import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';


import './_Header.scss';

import { Logo } from '../Icon';
import { Button } from '../index';

const Header = props => {

  const logout = async () => {
    localStorage.clear();
    window.location.href = '/sign-in';
  };

  return (
    <header className="header">
      <div className="container">
        {props.authentication.hasApiKey &&
        !props.authentication.isTempApiKey ? (
          <Fragment>
            <div className="header__left">
              <Link to="/dashboard">
                <Logo />
              </Link>
            </div>
            <div className="header__right">
              <Button data-testid="logout-button" click_event={logout}>
                Logout
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="header__left">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            {props.authentication.hasApiKey ? (
              <div className="header__right">
                <Button data-testid="logout-button" click_event={logout}>
                  Logout
                </Button>
              </div>
            ) : (
              <div className="header__right">
                <Link to="/sign-in" className="button right">
                  Sign In
                </Link>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  notAuthenticated: PropTypes.bool,
};

export default withRouter(Header);
