import React, { useMemo, useState } from 'react';
import * as S from './styled';
import { DataCard, Popup } from '../../../components';
import { Wrapper, Title } from '../styled';
import moment from 'moment';
import useConfirmRequest from './useConfirmRequest';
import { NairaAmount } from '../../../components/Icon';
import { useClientInfo } from '../../providers/Client/clientInfoContext';
import { Checkbox, SubmitButton } from '../../../components/FormikElements';
import { Form, Formik } from 'formik';
import { confirmLoanValidationSchema } from '../../../lib/validationSchemas';

const ConfirmRequest = () => {
  const {
    applicationLoading,
    data,
    offerLoading,
    onComplete,
    completeApplicationLoading,
    application,
  } = useConfirmRequest();

  const [showRepaymentBreakdown, togglePopup] = useState(false);

  const { generateApplicationOffers } = data || {};
  const { potentialOffer } = generateApplicationOffers || {};
  const { dateOfRepayment } = application?.application || {};
  const {  baseAmount, fullAmount, loanTenor, repaymentBreakdown, repaymentType, policy: suggestedPolicy } =
  potentialOffer || {};

  const { processingFee, processingFeeDebitMethod } = suggestedPolicy || {};
  const { clientInfo } = useClientInfo();


  const getTimeUnit = () => {
    if (!loanTenor) return '';
    const match = loanTenor.match(/^(\d+(\.\d+)?)\s*([a-zA-Z]+)(?:\(s\))?$/);
    if (match) {
      const number = parseFloat(match[1]);
      let unit = match[3].toLowerCase();
      if (number === 1) {
        if (unit.endsWith('s')) {
          unit = unit.slice(0, -1);
        }
      } else {
        if (!unit.endsWith('s')) {
          unit = unit + 's';
        }
      }
      return `${number} ${unit}`;
    }
    return '';
  };
  
  const tenor = getTimeUnit();
  
  const rows = useMemo(() => {
    let tableRows = [];
    let totalPrincipal = 0;
    let totalInterest = 0;
    let totalPayment = 0;

    tableRows = repaymentBreakdown?.map((repayment, index) => {
      totalPrincipal += repayment?.principalPortion;
      totalInterest += repayment?.interestPortion;
      totalPayment += repayment?.expectedPayment;
      return (
        <S.TableRow key={`repayment_${index}`}>
          <S.TableData>{moment(repayment?.dueDate).format('ll')}</S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.principalPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.interestPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.expectedPayment} />
          </S.TableData>
        </S.TableRow>
      );
    });
    tableRows?.push(
      <S.TableRow key={`repayment_total`}>
        <S.TableData>
          <b>Total</b>
          <span>Amount</span>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPrincipal} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalInterest} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPayment} />
          </b>
        </S.TableData>
      </S.TableRow>,
    );
    return tableRows;
  }, [repaymentBreakdown]);

  return (
    <Wrapper>
      <DataCard
        loading={
          applicationLoading || offerLoading || completeApplicationLoading
        }
      >
        <Title>Confirm your loan application</Title>

        <Formik
          initialValues={{
            policyCheck: '',
          }}
          validationSchema={confirmLoanValidationSchema}
          onSubmit={() => {
            onComplete();
          }}
        >
          {({ values }) => (
            <Form>
              <>
                <S.Summary>
                  <S.Detail>
                    <p>Requested Amount</p>
                    <p>
                      <NairaAmount amount={baseAmount} />
                    </p>
                  </S.Detail>
                  <S.Detail>
                    <p>Repayment Amount</p>
                    <p>
                      <NairaAmount amount={fullAmount} />
                    </p>
                  </S.Detail>
                  <S.Detail>
                    <p>Repayment Date</p>
                    <p>{moment(dateOfRepayment)?.format('ll')}</p>
                  </S.Detail>
                  <S.Detail>
                    <p>Interest Rate</p>
                    <p>{`${
                      suggestedPolicy?.interestRate?.value 
                    }% ${repaymentType}`}</p>
                  </S.Detail>
                  <S.Detail>
                    <p>Loan Tenor</p>
                    <p>{tenor}</p>
                  </S.Detail>
                  {processingFee?.value > 0 && (
                    <S.Detail>
                      <p>
                        {application?.application?.loanCategory?.products[0]?.processingFeeName?.trim() || "Processing Fee"}
                      </p>
                      <p>
                        {processingFee?.calcBy === 'PERCENTAGE' ? (
                          `${processingFee?.value}%`
                        ) : (
                          <NairaAmount amount={processingFee?.value} />
                        )}
                      </p>
                    </S.Detail>
                  )}
                </S.Summary>
                <S.ViewRepaymentButton onClick={() => togglePopup(true)} type="button">
                  View Repayment Details
                </S.ViewRepaymentButton>

                {processingFee?.value > 0 && (
                  <S.ProcessingFeeInfo>
                    If your loan is approved, a{' '} 
                    {application?.application?.loanCategory?.products[0]
                      ?.processingFeeName?.trim() || 'Processing Fee'}{' '}
                    of{' '}
                    {processingFee?.calcBy === 'PERCENTAGE' ? (
                      `${processingFee?.value}%`
                    ) : (
                      <NairaAmount amount={processingFee?.value} />
                    )}{' '}
                    will be deducted from the loan amount{' '}
                    {processingFeeDebitMethod === 'POST_DISBURSEMENT'
                      ? 'after'
                      : 'before'}{' '}
                    credit to your bank account.
                  </S.ProcessingFeeInfo>
                )}

                <Checkbox name="policyCheck">
                  {`I have read all the information provided in my application and
                    would like to be considered as a beneficiary under the
                    ${clientInfo?.name} Scheme. The information provided in my
                    application is accurate and I understand there are penalties
                    for providing any false information to the ${clientInfo?.name}.`}
                </Checkbox>

                <SubmitButton
                  disabled={!values.policyCheck}
                  type="submit"
                  value="Next"
                />

                {showRepaymentBreakdown && (
                  <S.Modal>
                    <Popup title="Repayment Breakdown">
                      <S.CloseIcon onClick={() => togglePopup(false)} />
                      <S.Table>
                        <S.TableHeader>
                          <S.TableRow>
                            <S.TableHeading span="col">
                              Repayment Date
                            </S.TableHeading>
                            <S.TableHeading span="col">
                              Principal
                            </S.TableHeading>
                            <S.TableHeading span="col">Interest</S.TableHeading>
                            <S.TableHeading span="col">
                              Total Payment
                            </S.TableHeading>
                          </S.TableRow>
                        </S.TableHeader>
                        <S.TableBody>{rows}</S.TableBody>
                      </S.Table>
                      <S.ContinueButton onClick={() => togglePopup(false)}>
                        Continue
                      </S.ContinueButton>
                    </Popup>
                  </S.Modal>
                )}
              </>
            </Form>
          )}
        </Formik>
      </DataCard>
    </Wrapper>
  );
};

export default ConfirmRequest;
