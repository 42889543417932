import React, { useEffect } from 'react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { bankDetailsSchema } from '../../../../lib/validationSchemas';
import {
  Input,
  NumberInput,
  Select,
} from '../../../../components/FormikElements';
import * as S from '../styled';
import { useLazyQuery } from '@apollo/client';
import { RESOLVE_ACCOUNT_NUMBER } from '../../../SignUp/queries';
import { handleGraphQLErrors } from '../../../../lib/utils';

export const GetUserAccountName = ({
  setAccountNameError,
  setIsLoading,
  accountName,
  setAccountName,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [runResolveAccountNumber, { loading }] = useLazyQuery(
    RESOLVE_ACCOUNT_NUMBER,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setAccountNameError(
          errorMessage ||
            `We are unable to get the account name linked to the
            account number provided, check your account number and try again`,
        );
        setAccountName(null);
      },
      onCompleted({ resolveAccountNumber }) {
        setAccountName(resolveAccountNumber.accountName);
        setFieldValue(props.name, resolveAccountNumber.accountName);
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    const { accountNumber, bankId, accountType } = values;

    if (accountNumber && accountNumber.length === 10 && bankId && accountType) {
      runResolveAccountNumber({
        variables: { accountNumber, bankId, accountType },
      });
    } else if (accountName) setAccountName(null);
  }, [values]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return accountName ? (
    <Input {...props} {...field} disabled="disabled" />
  ) : null;
};

const AddBankForm = ({
  handleAddBank,
  bankList,
  accountName,
  setError,
  setAccountName,
  setIsLoading,
  selectedBankId,
  accountNumber,
}) => {
  return (
    <>
      <S.AddBankAccount className={'active'}>
      <Formik
        initialValues={{
          bankName: '',
          bankId: selectedBankId || '',
          accountNumber: accountNumber || '',
          accountName: accountName,
          accountType: 'personal',
        }}
        validationSchema={bankDetailsSchema}
        onSubmit={(values) => {
          handleAddBank(values);    
        }}
          >
            {({ submitForm }) => (
              <Form>
                <Select name="accountType">
                  <option value="personal">Personal</option>
                  <option value="business">Business</option>
                </Select>

                <Select name="bankId">
                  <option value="">--Select a bank--</option>
                  {bankList}
                </Select>

                <NumberInput
                  name="accountNumber" 
                  placeholder="Account Number"
                  maxLength="10"
                />
                <GetUserAccountName
                  setAccountNameError={setError}
                  setIsLoading={setIsLoading}
                  setAccountName={setAccountName}
                  accountName={accountName}
                  name="accountName"
                />

                <S.AddBankAccountButton 
                  type="button"
                  disabled={!accountName}
                  onClick={submitForm}
                >
                  Add Bank Details
                </S.AddBankAccountButton>
              </Form>
            )}
          </Formik>

      </S.AddBankAccount>
    </>
  );
};

export default AddBankForm;
