import moment from 'moment';
import React, { Fragment, useState } from 'react';
import {
  LoanAdjustmentProvider,
  UploadRequestedDocumentProvider,
  UploadSupportingDocumentProvider,
} from '../../containers/providers';
import RepaymentBreakdownProvider from '../../containers/providers/Repayments/RepaymentBreakdownProvider';
import { logEvent } from '../../lib/GAHelper';
import { bytesToSize, camelize } from '../../lib/utils';
import { NairaAmount, UploadIcon } from '../Icon';
import { Alert, Button, Input, Loader, Popup } from '../index';
import './_ReviewFeedback.scss';

const ReviewFeedback = ({ application, refetch }) => (
  <LoanAdjustmentProvider>
    {({ approveUpdatedLoanAmount, declineUpdatedLoanAmount }) => {
      return (
        <UploadRequestedDocumentProvider>
          {({ uploadRequestedSupportingDocument }) => {
            return (
              <UploadSupportingDocumentProvider>
                {({
                  uploadSupportingDocumentFile,
                  errorMessage,
                  setErrorMessage,
                }) => {
                  return (
                    <ReviewFeedbackWrapper
                      application={application}
                      refetch={refetch}
                      uploadSupportingFile={uploadSupportingDocumentFile}
                      uploadRequestedSupportingDocument={
                        uploadRequestedSupportingDocument
                      }
                      mutationError={errorMessage}
                      approveUpdatedAmount={approveUpdatedLoanAmount}
                      declineUpdatedAmount={declineUpdatedLoanAmount}
                      setErrorMessage={setErrorMessage}
                    />
                  );
                }}
              </UploadSupportingDocumentProvider>
            );
          }}
        </UploadRequestedDocumentProvider>
      );
    }}
  </LoanAdjustmentProvider>
);

const ReviewFeedbackWrapper = ({
  application = {},
  refetch,
  uploadSupportingFile,
  uploadRequestedSupportingDocument,
  approveUpdatedAmount,
  declineUpdatedAmount,
  mutationError,
  setErrorMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [documentDetails, setDocumentDetails] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [breakdownPopup, setBreakdownPopup] = useState(false);

  const pendingFeedback =
    application &&
    application.status &&
    application.status.name !== 'APPROVED' &&
    application.status.name !== 'DENIED' &&
    application.reviewFeedbacks &&
    application.reviewFeedbacks.find(
      feedback =>
        feedback.status === 'PENDING' &&
        feedback.reviewType !== 'OFFER_LETTER_REQUEST',
    );

  const pendingDocumentFeedback =
    pendingFeedback &&
    pendingFeedback.documentRequests.length !== 0 &&
    pendingFeedback.documentRequests.find(
      document => document.status === 'PENDING',
    );

  const documentRequests = pendingDocumentFeedback
    ? pendingFeedback.documentRequests.map(document => ({
        ...document,
        name: camelize(document.title),
      }))
    : [];

  const toggleRepaymentBreakdown = e => {
    e.preventDefault();
    e.stopPropagation();

    setBreakdownPopup(!breakdownPopup);
  };

  const handleDocumentSelection = e => {
    setError(false);
    setLoading(true);

    const { name:id, files, validity } = e.target;
    const file = files[0];

    if (file) {
      setDocumentDetails(prevState => ({
        ...prevState,
        [id]: {
          fileName: file.name,

          fileSize: bytesToSize(file.size),
        },
      }));
    } else {
      setDocumentDetails('');
    }

    setLoading(false);

    if (file) {
      const data = { file, validity };
      const documentRequest = documentRequests.find(
        document => camelize(document?.id) === id,
      );

      uploadSelectedDocument(data, documentRequest?.title, id);
    }
  };

  const uploadSelectedDocument = async (selectedFile, documentName, docId) => {
    logEvent('Review Feedback - Upload Supporting Document');

    setErrorMessage(false);
    setError(false);
    setLoading(true);

    const response = await uploadSupportingFile(
      selectedFile,
      documentName,
      application.user.id,
    );

    setLoading(false);

    const error =
      'There was an error uploading your document, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ supportingDocuments: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ supportingDocuments: error });
      return;
    }

    const { uploadSupportingDocument } = response.data;

    if (!uploadSupportingDocument) {
      setError({ supportingDocuments: error });
      return;
    }

    setSelectedDocuments(prevState => ({
      ...prevState,
      [docId]: uploadSupportingDocument.id,
    }));
  };

  const handleRequestedSupportingDocument = async () => {
    logEvent('Review Feedback - Upload Requested Supporting Document');

    setError(false);
    setLoading(true);

    let requestedDocuments = [];
    pendingFeedback.documentRequests.forEach(document => {
      const documentKey = document?.id;

      requestedDocuments.push({
        documentRequestId: document.id,
        uploadedDocumentId: selectedDocuments[documentKey],
      });
    });

    const requestId = pendingFeedback.id;
    const error =
      'There was an error uploading your document, please try again';

    const response = await uploadRequestedSupportingDocument({
      requestId,
      requestedDocuments,
    });

    if (response == null || typeof response === 'undefined') {
      setError({ supportingDocuments: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ supportingDocuments: error });
      return;
    }

    const { success } = response.data.uploadRequestedSupportingDocument;

    if (!success) {
      setError({ supportingDocuments: error });
      return;
    }

    setSuccess({ supportingDocuments: 'Document Successfully Uploaded' });
    setLoading(false);

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const handleUpdatedAmountApproval = async () => {
    logEvent('Review Feedback - Approve Updated Loan Amount');

    setError(false);
    setLoading(true);

    const requestId = pendingFeedback.id;
    const applicationId = application.id;

    const response = await approveUpdatedAmount({
      requestId,
      applicationId,
    });

    setLoading(false);

    const error =
      'There was an error approving the updated loan amount, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ loanAdjustment: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ loanAdjustment: error });
      return;
    }

    const { approveUpdatedLoanAmount } = response.data;

    if (!approveUpdatedLoanAmount) {
      setError({ loanAdjustment: error });
      return;
    }

    setSuccess({
      loanAdjustment:
        'Updated loan amount approved. We will review and disburse your loan shortly.',
    });

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const handleUpdatedAmountDecline = async () => {
    logEvent('Review Feedback - Decline Updated Loan Amount');

    setError(false);
    setLoading(true);

    const requestId = pendingFeedback.id;
    const applicationId = application.id;

    const response = await declineUpdatedAmount({
      requestId,
      applicationId,
    });

    setLoading(false);

    const error =
      'There was an error declining the updated loan amount, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ loanAdjustment: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ loanAdjustment: error });
      return;
    }

    const { approveUpdatedLoanAmount } = response.data;

    if (!approveUpdatedLoanAmount) {
      setError({ loanAdjustment: error });
      return;
    }

    setSuccess({
      loanAdjustment:
        'Updated loan amount declined. Please, apply for a new loan.',
    });

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  return (
    <Fragment>
      {loading && <Loader />}

      {pendingFeedback &&
        (pendingDocumentFeedback ? (
          <div className="supporting_documents">
            <Popup title="Document Upload Request" classes="loan-requirements">
              {!mutationError
                ? error.supportingDocuments && (
                    <Alert classes="warning">
                      {error.supportingDocuments}!
                    </Alert>
                  )
                : mutationError && (
                    <Alert classes="warning">{mutationError}!</Alert>
                  )}

              {success.supportingDocuments && (
                <Alert classes="success">{success.supportingDocuments}!</Alert>
              )}
              <h5 className="popup_text">
                {pendingDocumentFeedback.message
                  ? pendingDocumentFeedback.message
                  : 'Your application requires the following documents for it to be complete.'}
              </h5>
              <div className="upload_documents">
                {documentRequests.map((requestedDoc, docIndex) => (
                  <label key={`doc_${docIndex}`} className="document-upload">
                    <Input
                      name={requestedDoc?.id}
                      type="file"
                      onChange={handleDocumentSelection}
                      classes={`border-bottom`}
                      accept="image/png, image/jpeg, application/pdf, application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />

                    <div className="document">
                      <UploadIcon />
                      <span className="center-text document-detail">
                        {documentDetails[requestedDoc?.id]
                          ? `${documentDetails[requestedDoc?.id].fileName} (${
                              documentDetails[requestedDoc?.id].fileSize
                            })`
                          : `Upload ${requestedDoc.title}`}
                      </span>
                    </div>
                  </label>
                ))}

                <div className="decision_buttons">
                  {/* <Button
                    classes="decline_button secondary"
                    children="Cancel"
                  /> */}

                  <Button
                    disabled={
                      Object.keys(selectedDocuments).length !==
                      documentRequests.length || success?.supportingDocuments === 'Document Successfully Uploaded'
                    }
                    click_event={handleRequestedSupportingDocument}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Popup>
          </div>
        ) : !breakdownPopup ? (
          <Popup title="Loan Adjustment" classes="loan-requirements">
            {error.loanAdjustment && (
              <Alert classes="warning">{error.loanAdjustment}</Alert>
            )}
            {success.loanAdjustment && (
              <Alert classes="success">{success.loanAdjustment}</Alert>
            )}
            <div className="loan_adjustment">
              <h5 className="popup_text">
                Your loan has been updated. Please, review the loan details
                below.
              </h5>
              <h5 className="loan_text">New Loan Amount</h5>
              <span className="loan_amount">
                <NairaAmount amount={application.amount} />
              </span>
              <h5 className="loan_text">Next repayment date</h5>
              <span className="loan_amount">
                {moment(application.dateOfRepayment).format('ll')}
              </span>

              <h5 className="popup_text">
                By choosing i accept below your loan amount will be change to
                the current amount.
              </h5>
              <div className="adjustment_link">
                <Button
                  classes="button-link"
                  click_event={toggleRepaymentBreakdown}
                >
                  <span className="link">View repayment Breakdown</span>
                </Button>
              </div>

              <div className="decision_buttons">
                <Button
                  classes="decline_button secondary"
                  click_event={handleUpdatedAmountDecline}
                >
                  I DO NOT ACCEPT
                </Button>
                <Button click_event={handleUpdatedAmountApproval}>
                  I ACCEPT
                </Button>
              </div>
            </div>
          </Popup>
        ) : (
          <div className="breakdown-popup">
            <Popup title="Repayment Breakdown">
              <RepaymentBreakdownProvider
                policyId={application.policy.id}
                principalAmount={application.amount}
                duration={application.loanDuration}
                moratoriumPeriod={
                  application.moratoriumData &&
                  application.moratoriumData.numberOfPeriods != null
                    ? application.moratoriumData.numberOfPeriods
                    : 0
                }
              >
                {({ repaymentBreakdown, repaymentTable }) =>
                  repaymentBreakdown && repaymentTable
                }
              </RepaymentBreakdownProvider>
              <div className="decision_buttons">
                <Button
                  classes="close_button"
                  click_event={toggleRepaymentBreakdown}
                >
                  CLOSE
                </Button>
              </div>
            </Popup>
          </div>
        ))}
    </Fragment>
  );
};

export default ReviewFeedback;
