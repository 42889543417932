import React from 'react';

import { OriginateFormGenerator } from '@indicina1/originate-form-builder';
import { Button } from '../../../components';
import theme from '../../../stylesheets/theme';
import LoaderPage from '../../LoanApplication/LoaderPage';

const FormBuilder = ({
  applicationForm,
  handleSubmit,
  formIndex,
  defaultFormValues,
}) => {
  return (
    <OriginateFormGenerator
      key={`${defaultFormValues}_${formIndex}`}
      tabs={applicationForm}
      onSubmit={handleSubmit}
      step={formIndex}
      theme={theme}
      cardComponent={
        <LoaderPage/>
      }
      bankComponent={
        <LoaderPage/>
      }
      defaultFormValues={defaultFormValues}
    >
      <Button classes={'custom-btn'} type={'submit'}>
        Next
      </Button>
    </OriginateFormGenerator>
  );
};

export default FormBuilder;
