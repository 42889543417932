import React, {  useCallback, useEffect, useLayoutEffect } from 'react';
import {
  BankStatementUpload,
  ConfirmRequest,
  EmailVerification,
  Mbs,
  PhoneVerification,
  RefereeDetails,
  GovernmentIDCard,
  WorkIdUploadScreen,
  AddCard,
  BankAccount
} from '../../LoanApplication';
import { DataCard } from '../../../components';
import { Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useCustomForm } from './useCustomForm';
import { ApplicationProvider } from '../ApplicationContext';
import './_CustomApplication.scss';
import MbsOtp from '../../LoanApplication/Mbs/components/MbsOtp';
import MbsInstruction from '../../LoanApplication/Mbs/components/MbsInstruction';
import { NEW_LOAN_ROUTES } from '../../LoanApplication/routes';
import CustomFormApplicationRoute from './CustomFormApplicationRoute';
import FormBuilder from './FormBuilder';
import { useCustomApplicationRoute } from '../../../hooks';

const CustomApplicationRouter = () => {
  const {
    formIndex,
    applicationForm,
    handleSubmit,
    defaultFormValues,
    saveFormLoading,
    getLatestLoading,
    loading,
    mutate,
    uploadDocumentLoading,
    uploadImageLoading,
    customApplicationForm,
    applicationId,
  } = useCustomForm();

  const { path } = useRouteMatch();
  const getPath = route => `${path}/${route}`;
  const activeFormTabs = applicationForm?.filter(tab => !tab.linkedToOption);
  const history = useHistory();
  const location = useLocation();

  const {getAllowedRoute, normalizePath, skipAddCard, setHasSkippedCard } = useCustomApplicationRoute();

  const checkAndRedirectToAllowedRoute = useCallback(async () => {
    const allowedRoute = await getAllowedRoute();
    if (allowedRoute && normalizePath(location.pathname) !== normalizePath(allowedRoute)) {
      history.replace(allowedRoute);
    }
  }, [getAllowedRoute, location.pathname, history, normalizePath ]);


 const customFormName = activeFormTabs?.[formIndex]?.builders?.[0]?.title


 useEffect(() => {
   if (customFormName  === 'Card' || customFormName  === 'Bank Info') {
    mutate({
      variables: {
        applicationId,
        data: { step: formIndex + 1, path: '' },
      },
    });
   }

 }, [customFormName])


  useLayoutEffect(() => {
    checkAndRedirectToAllowedRoute();
  }, [checkAndRedirectToAllowedRoute]);


  return (
    <DataCard
      loading={
        getLatestLoading ||
        saveFormLoading ||
        loading ||
        uploadDocumentLoading ||
        uploadImageLoading
      }
    >
      {Array.isArray(activeFormTabs) && activeFormTabs?.length > 0 && (
        <Switch>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.mbs)} exact>
            <Mbs customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.mbsOtp)} exact>
            <MbsOtp customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.mbsInstruction)} exact>
            <MbsInstruction customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.verifyEmail)} exact>
            <EmailVerification customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.verifyPhone)} exact>
            <PhoneVerification customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.addCard)} exact>
            <AddCard customForm={true} skipAddCard={skipAddCard} setHasSkippedCard={setHasSkippedCard} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.bankAccount)} exact>
            <BankAccount customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.referee)} exact>
            <RefereeDetails customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.idUpload)} exact>
            <GovernmentIDCard customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.workIdUpload)} exact>
            <WorkIdUploadScreen customForm={true} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.confirmLoan)} exact>
            <ConfirmRequest />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.uploadBankStatement)} exact>
            <BankStatementUpload customForm={true} />
          </CustomFormApplicationRoute>
          {formIndex < activeFormTabs?.length && (
            <CustomFormApplicationRoute path={`${path}/`}>
              <FormBuilder
                applicationForm={applicationForm}
                handleSubmit={handleSubmit}
                formIndex={formIndex}
                handleNext={mutate}
                defaultFormValues={defaultFormValues}
                customApplicationForm={customApplicationForm}
              />
            </CustomFormApplicationRoute>
          )}
        </Switch>
      )}
    </DataCard>
  );
};

const CustomApplication = () => {
  return (
    <ApplicationProvider>
      <section className="container application form-builder">
        <CustomApplicationRouter />
      </section>
    </ApplicationProvider>
  );
};

export default CustomApplication;

