import { useEffect, useState } from 'react';
import { useLoanApplicationContext } from '../../../hooks';

const useHomeAddressForm = () => {
  const {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    loanApplicationIsLoading,
    createApplicationTrace,
    indexSlug,
    goToPreviousPage,
    goToNextPage,
    user,
    userLoading,
    setCompletedStep,
  } = useLoanApplicationContext();

  const [currentAddress, setCurrentAddress] = useState({});

  const addHomeAddress = values => {
    setLoanApplicationState(prevState => ({
      ...prevState,
      ...values,
    }));
    updateUserDetails({ ...values }, () => {
      setCompletedStep('homeAddress');
      goToNextPage();
    });
  };

  useEffect(() => {
    if (!userLoading) {
      const { userMetadata } = user || {};
      setCurrentAddress({
        address: userMetadata?.find(meta => meta.name === 'address')?.value,
        state: userMetadata?.find(meta => meta.name === 'state')?.value,
        localGovernment: userMetadata?.find(
          meta => meta.name === 'localGovernment',
        )?.value,
      });
    }
  }, [user, userLoading]);

  return {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    loanApplicationIsLoading,
    createApplicationTrace,
    indexSlug,
    currentAddress,
    addHomeAddress,
    goToPreviousPage,
    userLoading,
  };
};

export default useHomeAddressForm;
