import React, { useCallback, useEffect } from 'react';
import * as S from './styled';
import { useSignupContext } from '../../../hooks';
import { Alert, DataCard } from '../../../components';
import { OriginateFormGenerator } from '@indicina1/originate-form-builder';
import theme from '../../../stylesheets/theme';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER_INFO } from '../mutations';
import { generateCardReference, trimGraphQLErrors } from '../../../lib/utils';
import Constants from '../../../lib/constants';
import { logEvent } from '../../../lib/GAHelper';
import { NEW_LOAN_ROUTES } from '../../../containers/LoanApplication/routes';
import { UPLOAD_SUPPORTING_DOCUMENT } from '../../../hooks/useDocumentUploader';
import { UPLOAD_IMAGE } from '../../../hooks/useImageUploader';

/* eslint-disable */
const CustomerInfo = ({ history }) => {
  const {
    updateContextState,
    clientInfo,
    clientInfoLoading,
    updateAuthType,
    signupLoading,
    signupState,
    setSignupState,
    viewerLoading,
    viewerData,
    viewerError,
  } = useSignupContext();

  const user = viewerData?.loaded?.viewer?.me;
  const account = viewerData?.loaded?.viewer?.account;
  const applications = account?.applications?.nodes;
  const applicationCount = applications?.length || 0;


  const [updateCustomerInfoMutation, { loading, error }] = useMutation(
    UPDATE_CUSTOMER_INFO,
    {
      onCompleted: () => {
        const payStackMetadata = {
          accountId: signupState?.account?.id,
          cardReference: generateCardReference(),
          transactionType: Constants.transactType.transactionType,
        };

        setSignupState((prevState) => ({
          ...prevState,
          payStackMeta: payStackMetadata,
          phoneNumber: signupState?.user?.bvnStatus?.phone || user?.bvnStatus?.phone,
        }));

        updateContextState('loan-product', {
          payStackMeta: payStackMetadata,
        });
        updateAuthType();
        history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);
      },
    }
  );

  const [mutateImage, { loading: uploadImageLoading, error:uploadImageError }] = useMutation(
    UPLOAD_IMAGE,
    {},
  );

  const [mutateFile, { loading: uploadDocumentLoading, error:uploadSupportingDocError }] = useMutation(
    UPLOAD_SUPPORTING_DOCUMENT,
    {},
  );

  const uploadSelectedImage = useCallback(async (selectedFile, fileName, type) => {
    const { validity, file } = selectedFile;
    if (validity) {
      const { data } = await mutateImage({
        variables: { image: file, fileName, type },
      });
      return data;
    }
  },[]);
  
  const uploadSelectedFile = useCallback( async (selectedFile, documentName) => {
    const { validity, file } = selectedFile;
    if (validity) {
      const { data } = await mutateFile({
        variables: { file, documentName, userId: user?.id },
      });
      return data;
    }
  },[user]);

  const handleUploads = async ({
    name,
    fileDetails,
    documentName,
    documentNumber,
  }) => {
    if (name === 'workId' || name === 'selfie') {
      return await handleImageUpload(name, fileDetails);
    }
    if (name === 'governmentId') {
      return await handleGovernmentIdUpload(
        documentName,
        documentNumber,
        fileDetails,
      );
    }
    return await handleDocumentUpload(name, fileDetails);
  };

  const handleImageUpload = useCallback(
    async (name, fileDetails) => {
      const type = name === 'workId' ? 'WORKID' : 'PASSPORT';
      const namePrefix = name === 'workId' ? 'work_id_' : 'selfie_';
      const { firstName, lastName, id } = user || {};
      const fileName = `${namePrefix}${firstName}_${lastName}_${id}`;
      const { uploadImageAndSaveToUserMetaData } = await uploadSelectedImage(
        fileDetails,
        fileName,
        type,
      );
      const { fileUrl, key, bucket } = uploadImageAndSaveToUserMetaData || {};
      return { key, bucket, url: fileUrl };
    },
    [],
  );

  const handleGovernmentIdUpload = useCallback(
    async (documentName, documentNumber, fileDetails) => {
      const type = 'GOVERNMENTID';
      const { firstName, lastName, id } = user || {};
      const fileName = `${documentName}_${documentNumber}_${firstName}_${lastName}_${id}`;
      const { uploadImageAndSaveToUserMetaData } = await uploadSelectedImage(
        fileDetails,
        fileName,
        type,
      );
      const { fileUrl, key, bucket } = uploadImageAndSaveToUserMetaData || {};
      return { key, bucket, url: fileUrl };
    },
    [],
  );

  const handleDocumentUpload = useCallback(
    async (name, fileDetails) => {
      const { firstName, lastName, id } = user || {};
      const fileName = `${name}_${firstName}_${lastName}_${id}`;
      const { uploadSupportingDocument } = await uploadSelectedFile(
        fileDetails,
        fileName,
      );
      const { file } = uploadSupportingDocument || {};
      return { url: file?.url, bucket: file?.bucket, key: file?.key };
    },
    [],
  );

  const reFormatFormData = formData => {
    const name = 'Customer Information';

    const keySet = new Set(formData[name]?.files?.map(item => item.name));

    const filteredFormData = Object.keys(formData[name])
      .filter(key => !keySet.has(key))
      .reduce((result, key) => {
        result[key] = formData[name][key];
        return result;
      }, {});

    return filteredFormData;
  };


  const updateCustomerInfo = async (data) => {
    logEvent('Signup', 'Add Customer Info.');
    const name = 'Customer Information';

    if (data[name]?.files.length > 0) {
      const newFiles = data[name]?.files?.map(async file => {
        const { url, key, bucket } = await handleUploads(file);
        file =
          file.name === 'governmentId'
            ? {
                name: file?.name,
                fileDetails: {
                  url,
                  key,
                  bucket,
                  documentName: file?.documentName,
                  documentNumber: file?.documentNumber,
                },
              }
            : {
                name: file?.name,
                fileDetails: {
                  url,
                  key,
                  bucket,
                },
              };
        return file;
      });
      data[name].files = await Promise.all(newFiles);
      const filteredFormData = reFormatFormData(data);
      data[name] = filteredFormData;
    }

    updateCustomerInfoMutation({
      variables: {
        input: {
          kycInformation: {
            data
          },
        },
      },
    });
    setSignupState((prevState) => ({
      ...prevState,
      data,
    }));
  };

  const handleSubmit = (data) => {
    updateCustomerInfo(data);
  };

  useEffect(() => { 
    if (applicationCount === 0 && user?.kycInformation) {
      history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);  
    }
  }, [user?.kycInformation, applicationCount]);

  return (
    <S.Wrapper>
      <DataCard loading={clientInfoLoading || loading || signupLoading || viewerLoading || uploadImageLoading || uploadDocumentLoading }>

        {(viewerError || error || uploadImageError || uploadSupportingDocError) && (
          <Alert classes="error">
            {trimGraphQLErrors(viewerError || error || uploadImageError || uploadSupportingDocError) || 'There was an error processing your request'}
          </Alert>
        )}

        {!clientInfoLoading && (
          <OriginateFormGenerator
            onSubmit={handleSubmit}
            theme={theme}
            step={0}
            tabs={[clientInfo?.kycConfiguration?.form]}
            defaultFormValues={''}
          >
            <S.Button type="submit">Proceed</S.Button>
          </OriginateFormGenerator>
        )}
      </DataCard>
    </S.Wrapper>
  );
};

export default CustomerInfo;
