import React, { useEffect, useMemo, useState } from 'react';
import { DataCard } from '../../../components';
import * as S from './styled';
import { useSelectApplicationBank } from './useBankAccount';
import { Field, Form, Formik } from 'formik';
import AddBankForm from './components/AddBankForm';

const BankAccount = ({ customForm }) => {
  const {
    loading,
    banks,
    bankAccounts,
    selectedBankAccount,
    showContinueButton,
    handleContinueButton,
    showUploadBankStatementButton,
    handleUploadBankStatementButton,
    showTriggerOkraButton,
    showAddNewBankAccountForm,
    toggleShowAddNewBankAccountForm,
    handleAddBankDetails,
    accountName,
    runResolveAccountNumber,
    setAccountName,
    setIsLoading,
    selectedBankId,
    accountNumber,
    setShowAddNewBankAccountForm,
    showErrorAlert,
    handleAccountBankSwitch,
    initiateOkra,
    requiresOkraTransactions,
  } = useSelectApplicationBank({ customForm });

  const bankList = useMemo(() => {
    return (banks || []).map((bank, index) => (
      <option key={index} value={bank.id}>
        {bank.name}
      </option>
    ));
  }, [banks]);

  const handleBankAccountChange = value => {
    handleAccountBankSwitch(value);
  };

  const handleAddBank = values => {
    const payload = {
      inputAccountName: values?.accountName,
      inputAccountNumber: values?.accountNumber,
      inputBankId: values?.bankId,
    };

    handleAddBankDetails(payload);
  };

  useEffect(() => {
    if (!loading && bankAccounts.length === 0)
      setShowAddNewBankAccountForm(true);
  }, [loading]);

  const handleSubmit = () => {};

  useEffect(() => {
    if (bankAccounts.length === 1) {
      handleBankAccountChange(bankAccounts[0].id);
    }
  }, [bankAccounts]);

  return (
    <S.Wrapper>
      <DataCard loading={loading}>
        {bankAccounts?.length > 0 && (
          <Formik
            initialValues={{
              selectedBankAccount: selectedBankAccount,
            }}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {() => (
              <Form>
                <S.BankAccountsWrapper>
                  {bankAccounts?.map(bankAccount => (
                    <BankAccountItem
                      key={bankAccount?.id}
                      bankAccount={bankAccount}
                      selectedBankAccount={selectedBankAccount}
                      handleBankAccountChange={handleBankAccountChange}
                      requiresOkraTransactions={requiresOkraTransactions}
                      banks={banks}
                    />
                  ))}
                </S.BankAccountsWrapper>
              </Form>
            )}
          </Formik>
        )}

        {bankAccounts?.length > 0 && (
          <S.AddNewBankAccountButton
            type="button"
            onClick={toggleShowAddNewBankAccountForm}
          >
            {!showAddNewBankAccountForm ? 'Add New Bank Account' : 'Close Form'}
          </S.AddNewBankAccountButton>
        )}

        {showAddNewBankAccountForm && (
          <AddBankForm
            handleAddBank={handleAddBank}
            bankList={bankList}
            handleResolveAccountName={runResolveAccountNumber}
            accountName={accountName}
            setAccountName={setAccountName}
            setIsLoading={setIsLoading}
            selectedBankId={selectedBankId}
            accountNumber={accountNumber}
            setError={showErrorAlert}
          />
        )}

        <S.ButtonsGroup>
          {!showAddNewBankAccountForm && showContinueButton && (
            <S.ContinueButton type="button" onClick={handleContinueButton}>
              Continue
            </S.ContinueButton>
          )}

          {!showAddNewBankAccountForm && showTriggerOkraButton && (
            <S.ContinueButton type="button" onClick={initiateOkra}>
              Link account via Okra to fetch bank statement
            </S.ContinueButton>
          )}

          {!showAddNewBankAccountForm && showUploadBankStatementButton && (
            <S.UploadStatementButton
              type="button"
              onClick={handleUploadBankStatementButton}
            >
              Upload PDF Bank Statement
            </S.UploadStatementButton>
          )}
        </S.ButtonsGroup>
      </DataCard>
    </S.Wrapper>
  );
};

export default BankAccount;

const BankAccountItem = ({
  bankAccount,
  selectedBankAccount,
  handleBankAccountChange,
  requiresOkraTransactions,
  banks,
}) => {
  const isDisabled = !banks.some(bank => bank.code === bankAccount?.bank?.code);

  return (
    <S.Detail>
      <S.BankDetail>
        <S.RadioTitle>
          {bankAccount.accountName} - {bankAccount.accountNumber}
        </S.RadioTitle>

        <S.RadioDescription>
          {bankAccount?.bank?.name} {bankAccount?.isDefault && `(Default)`}
        </S.RadioDescription>
        {requiresOkraTransactions && bankAccount?.okraRecord?.id && (
          <S.RadioDescription>Linked with Okra</S.RadioDescription>
        )}
      </S.BankDetail>

      <Field name="selectedBankAccount">
        {({ field, meta }) => (
          <>
            <BankAccountItemRadio
              meta={meta}
              field={field}
              value={bankAccount.id}
              selectedBankAccount={selectedBankAccount}
              handleBankAccountChange={handleBankAccountChange}
              disabled={isDisabled}
            />
          </>
        )}
      </Field>
    </S.Detail>
  );
};

const BankAccountItemRadio = ({
  value,
  field,
  selectedBankAccount,
  handleBankAccountChange,
  disabled,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <S.RadioInputWrapper>
        <S.RadioInput
          type="radio"
          name="radio"
          value={value}
          checked={selectedBankAccount?.id === value}
          onChange={e => {
            field.onChange(e);
            handleBankAccountChange(e?.target?.value);
          }}
          disabled={disabled}
        />
        <S.Span />
      </S.RadioInputWrapper>
      {disabled && hovered && (
        <S.Tooltip>
          Bank is not supported by product, please select/add a different bank
        </S.Tooltip>
      )}
    </div>
  );
};

// const bankAccounts = [{ name: 'WOle', id: 'test', value: 'hey' }];
