import moment from 'moment';
import { PaystackButton } from 'paystackbutton';
import React from 'react';
import { DataCard, Button } from '../../../components';
import { NairaAmount } from '../../../components/Icon';
import * as S from './styled';
import { useAddCard } from './useAddCard';

const AddCard = ({ customForm, skipAddCard, setHasSkippedCard }) => {
  const {
    applicationLoading,
    amount,
    reference,
    channel,
    email,
    paystackPubKey,
    payStackCallback,
    initiateCardVerification,
    generatingReferenceLoading,
    getReferenceLoading,
    metadata,
    generateAddCardRef,
    handleSkipCardCollection,
    collectionMethod,
    userLoading,
    application,
    skipCardStep,
  } = useAddCard({ customForm, setHasSkippedCard });

  return (
    <S.Wrapper>
      <DataCard
        loading={
          applicationLoading ||
          generatingReferenceLoading ||
          getReferenceLoading||userLoading
        }
      >
        <S.Title>Add Debit Card</S.Title>
        <S.Subtitle>
          Please add the card that is linked to your main income account to
          increase your chances of getting a loan.
        </S.Subtitle>

        <S.Text style={{fontWeight: 900, color: "green", fontSize: "16px"}}>
          Ensure the expiry date on your ATM card is beyond 
          <S.Text style={{  fontWeight: 900, color: "green", fontSize: "16px", marginTop: "-10px" }}>
            {moment().add(application?.application?.loanDuration, 'days').format('ll')}
         </S.Text>
        </S.Text>
        
        <S.Aside>
          Please note that <NairaAmount amount={amount} /> is a
          <span className="warning"> NON-REFUNDABLE FEE</span> for bank
          statement check, credit bureau check and card tokenization.
          <br /> This fee is not a guarantee of loan disbursement.
        </S.Aside>
        {collectionMethod.length > 0 && (
          <S.Aside>
            Having trouble adding a card?
            <Button
              classes="button-link left"
              onClick={handleSkipCardCollection}
            >
              <small className="warning">Skip Add Debit Card Step</small>
            </Button>
          </S.Aside>
        )}
        <PaystackButton
          text="Add Card"
          embed={false}
          className="paystackbutton"
          reference={reference}
          channels={channel}
          email={email}
          close={() => {}}
          amount={parseInt(amount) * 100}
          publicKey={paystackPubKey}
          metadata={metadata}
          onSuccess={payStackCallback}
          tag="button"
        />

        {!generatingReferenceLoading && !reference && (
          <S.ReferenceError>
            There was an error generating payment reference.
            <S.RetryButton
              onClick={() => {
                generateAddCardRef();
              }}
            >
              Please, retry.
            </S.RetryButton>
          </S.ReferenceError>
        )}
        <S.NextButton
          type="button"
          disabled={!reference}
          value="Add Card"
          onClick={initiateCardVerification}
        />

        {skipAddCard && (
            <Button
              classes="secondary"
              onClick={skipCardStep}
              style={{ width: "100%", margin: "20px 0", }}
            >
              <small>I will do this later</small>
            </Button>
        )}
      </DataCard>
    </S.Wrapper>
  );
};

export default AddCard;
