import gql from 'graphql-tag';

export const fragments = {
  user: gql`
    fragment UserDetails on User {
      id
      firstName
      lastName
      email
      phone
      status
      bvnStatus {
        bvn
        dateOfBirth
        gender
        phone
      }
      isBvnVerified
      isEmailConfirmed
      isPhoneConfirmed
      isWorkEmailConfirmed
    }
  `,
  userMetadata: gql`
    fragment UserMetadataDetails on UserMetadata {
      id
      name
      value
    }
  `,
  supportingDocument: gql`
    fragment SupportingDocument on SupportingDocument {
      documentName
      file {
        key
        bucket
        etag
        url
      }
    }
  `,
  account: gql`
    fragment AccountDetails on Account {
      id
      name
    }
  `,
  portfolio: gql`
    fragment AccountPortfolio on Portfolio {
      id
      amount
      chargesAmount
      repayments {
        id
        repaymentDate
        status {
          name
        }
        amountPaid
        totalPayment
        outstandingPayment
        dueDate
      }
      taxAmount
      status {
        name
      }
      debitMandate {
        id
        payerPhone
        payerEmail
        amount
        mandateId
        electronicMandateIsSupported
        formUrl
        accountBank {
          id
        }
        requestId
        createdAt
        status {
          name
        }
      }
      createdAt
      fullAmount
      dateOfRepayment
      amountPaid
      amountDisbursed
      portfolioNumber
      policy {
        interestRate {
          value
        }
      }
      offlineRepayments {
        id
        amount
        status
      }
      debitMandateMeta {
        id
        status {
          name
        }
        type {
          id
          name
        }
        lidyaCollect {
          id
          collectStatus
        }
        activationDate
      }
      application {
        id
        status {
        name
        }
        account {
          name
          cards {
            id
            bankName
          }
          bankAccounts {
            id
            accountName
          }
        }
        loanCategory {
          id
          products {
            processingFeeName
            id
            applicationForm
          }
          loanCategoryAttributes {
            attribute {
              id
              name
            }
            value
          }
        }
        requiredSteps
        completedSteps
      }
    }
  `,
  application: gql`
    fragment AccountApplication on Application {
      id
      applicationNumber
      amount
      taxAmount
      baseAmount
      chargesAmount
      rejectReason
      customApplicationForm
      account {
        name
        cards {
          id
          bankName
        }
        bankAccounts {
          id
          accountName
        }
      }
      user {
        id
        isEmailConfirmed
        isPhoneConfirmed
      }
      policy {
        offerLetterEnabled
        id
        durationType
        interestRate {
          calcBy
          value
        }
        graduatedLoanCycles {
          interestRate
          amount
          maxLoanDuration
        }
      }
      reviewFeedbacks {
        id
        status
        reviewType
        documentRequests {
          id
          title
          message
          status
          document {
            fileUrl
            documentName
            file {
              key
              bucket
            }
          }
        }
      }
      portfolio {
        id
        fullAmount
        amountPaid
        portfolioNumber
        createdAt
        amountDisbursed
        lateRepaymentFee
        status {
          name
        }
        repayments {
          id
          repaymentDate
          status {
            name
          }
          amountPaid
          totalPayment
          outstandingPayment
          principalPortion
          interestPortion
          dueDate
        }
        policy {
          interestRate {
            value
          }
        }
        offlineRepayments {
          id
          amount
          status
        }
        debitMandateMeta {
          id
          status {
            name
          }
          lidyaCollect {
            id
            collectStatus
          }
          type {
            id
            name
          }
          activationDate
        }
      }
      status {
        name
      }
      fullAmount
      loanDuration
      dateOfRepayment
      moratoriumData {
        numberOfPeriods
      }
      reviewFeedbacks {
        id
        status
        documentRequests {
          id
          title
          message
          status
          document {
            documentName
            fileUrl
            id
            file {
              key
              bucket
            }
          }
        }
      }
      requiredSteps
      completedSteps
      createdAt
      loanCategory {
        id
        products {
          processingFeeName
          id
          applicationForm
        }
        loanCategoryAttributes {
          attribute {
            id
            name
          }
          value
        }
      }
      hasPdfBankStatement
      bankAccount {
        id
        bank {
          id
          name
        }
        accountNumber
        accountName
        isDefault
        okraRecord {
          id
          accountId
          directDebitAuthorised
        }
      }
      meta {
        key
        value
        document {
          id
          documentName
        }
      }
      credit {
        ticketNo
      }
    }
  `,
};

export const VIEWER_QUERY = gql`
  query ViewerQuery($supportingDocumentFilter: SupportingDocumentTypes) {
    viewer {
      id
      me {
        ...UserDetails
        userMetadata {
          ...UserMetadataDetails
        }
        supportingDocument(filterBy: $supportingDocumentFilter) {
          ...SupportingDocument
        }
        bvnStatus {
          bvn
        }
      }
      account {
        ...AccountDetails
        portfolios {
          nodes {
            ...AccountPortfolio
          }
        }
        applications(orderBy: createdAt_DESC) {
          nodes {
            ...AccountApplication
          }
        }
        bankAccounts {
          bank {
            id
            name
          }
          accountNumber
        }
        cards {
          bankName
          maskedPan
          expiryDate
          status
          isDefault
        }
      }
    }
  }
  ${fragments.user}
  ${fragments.userMetadata}
  ${fragments.account}
  ${fragments.portfolio}
  ${fragments.application}
  ${fragments.supportingDocument}
`;

export const GET_ENUM = gql`
  query getEnum($type: Enum!) {
    enumValues(type: $type) {
      name
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      token
    }
  }
`;

export const UPDATE_USER_EMAIL_MUTATION = gql`
  mutation updateUserEmail($userId: ID!, $email: String!) {
    updateUserEmail(input: { userId: $userId, email: $email }) {
      ok
      record {
        isEmailConfirmed
      }
    }
  }
`;

export const UPDATE_USER_DATA_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUserData(input: $input) {
      success
      records {
        ...UserMetadataDetails
      }
    }
  }
  ${fragments.userMetadata}
`;

export const PORTFOLIO_BY_ID_SUBSCRIPTION = gql`
  subscription PortfolioById($portfolioId: ID!) {
    portfolioByIdUpdates(id: $portfolioId) {
      event
      message
      portfolio {
        ...AccountPortfolio
      }
    }
  }
  ${fragments.portfolio}
`;

export const SINGLE_APPLICATION_QUERY = gql`
  query application($applicationNumber: String!) {
    application(applicationNumber: $applicationNumber) {
      ...AccountApplication
    }
  }
  ${fragments.application}
`;

export const TRIGGER_PERSONAL_EMAIL_CONFIRMATION = gql`
  mutation triggerPersonalEmailConfirmation {
    triggerPersonalEmailConfirmation {
      ok
    }
  }
`;

export const CONFIRM_PERSONAL_EMAIL = gql`
  mutation confirmPersonalEmail($code: String!) {
    confirmPersonalEmail(code: $code) {
      ok
    }
  }
`;

export const FETCH_FROM_S3_QUERY = gql`
  query fetchFromS3($bucket: String!, $key: String!) {
    fetchFromS3(bucket: $bucket, key: $key) {
      dataUrl
      status
    }
  }
`;
