import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import * as S from './styled';
import { getClientId, useSignupContext } from '../../../hooks';
import { Alert, DataCard } from '../../../components';
import { bvnValidationSchema } from '../../../lib/validationSchemas';
import {
  Input,
  NumberInput,
  SubmitButton,
} from '../../../components/FormikElements';
import { dobMaxSelectableDate, trimGraphQLErrors } from '../../../lib/utils';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_BVN_STATUS } from '../queries';
import moment from 'moment';

const BvnVerification = ({ history }) => {
  const {
    updateContextState,
    signupState,
    viewerLoading,
    viewerData,
    viewerError,
    getUserViewer,
  } = useSignupContext();
  const [bvnError, setBvnError] = useState(null);

  const clientId = getClientId();

  const user = viewerData?.loaded?.viewer?.me;
  const account = viewerData?.loaded?.viewer?.account;
  const applications = account?.applications?.nodes;
  const applicationCount = applications?.length || 0;

  const [getCustomerBvnStatusQuery, { loading, error, data }] = useLazyQuery(
    CUSTOMER_BVN_STATUS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getCustomerBvnStatus = ({ bvn, bvnPhoneDigits, bvnDOB }) => {
    const accountId = account?.id || signupState?.accountId;
    if (accountId) {
      getCustomerBvnStatusQuery({
        variables: {
          bvn,
          clientId,
          bvnPhoneDigits,
          bvnDOB,
          accountId,
        },
      });
    } else {
      setBvnError('There was an error processing your request');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(!user){
      getUserViewer()
    }
    if (applicationCount === 0 && user?.bvnStatus?.bvn) {
      history.push('/v1/sign-up/customer-info');
      return;
    }
  }, [applicationCount, user?.bvnStatus?.bvn]);

  return (
    <S.Wrapper>
      <DataCard loading={loading || viewerLoading}>
        <S.Title>BVN Verification</S.Title>

        {(error || bvnError) && (
          <Alert classes="error">
            {trimGraphQLErrors(error || viewerError || '') ||
              bvnError ||
              'There was an error processing your request'}
          </Alert>
        )}

        <Formik
          initialValues={{
            bvn: '',
            bvnPhoneDigits: '',
            bvnDOB: '',
          }}
          validationSchema={bvnValidationSchema}
          onSubmit={() => {
            updateContextState('customer-info');
            history.push('/v1/sign-up/customer-info');
          }}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            setFieldTouched,
            errors,
            touched,
          }) => {
            useEffect(() => {
              if (
                values?.bvn &&
                values?.bvnPhoneDigits &&
                values?.bvnDOB &&
                values?.bvn?.length === 11 &&
                values?.bvnPhoneDigits?.length === 4 &&
                !errors?.bvn &&
                !errors?.bvnPhoneDigits &&
                !errors?.bvnDOB
              ) {
                const convertBvnDOB = moment(values.bvnDOB).format(
                  'DD-MMM-YYYY',
                );
                getCustomerBvnStatus({
                  bvn: values?.bvn,
                  bvnPhoneDigits: values?.bvnPhoneDigits,
                  bvnDOB: convertBvnDOB,
                });
              }
            }, [values, errors]);

            return (
              <Form>
                <>
                  <NumberInput
                    onChange={e => {
                      setFieldValue('bvn', e.target.value);
                    }}
                    name="bvn"
                    value={values.bvn}
                    placeholder="Kindly enter your BVN"
                    maxLength="11"
                    decimalSeparator={false}
                  />

                  <NumberInput
                    name="bvnPhoneDigits"
                    placeholder="Last 4 digits of BVN phone number"
                    maxLength="4"
                    decimalSeparator={false}
                  />

                  <DatePicker
                    placeholderText="BVN Date of Birth"
                    name="bvnDOB"
                    selected={values.bvnDOB ? new Date(values.bvnDOB) : null}
                    onChange={date => {
                      setFieldValue('bvnDOB', date ? date.toISOString() : '');
                    }}
                    onChangeRaw={e => {
                      setFieldTouched('bvnDOB', true, true);
                      e.preventDefault();
                    }}
                    showYearDropdown
                    dateFormat="dd/MMMM/yyyy"
                    showMonthDropdown
                    dropdownMode="select"
                    className="datepicker"
                    autoComplete="off"
                    maxDate={dobMaxSelectableDate}
                    onBlur={handleBlur}
                  />
                  {errors.bvnDOB && touched.bvnDOB ? (
                    <span className="datepicker_error">{errors.bvnDOB}</span>
                  ) : (
                    ''
                  )}
                </>

                {data?.customerBvnStatus?.firstName &&
                  data?.customerBvnStatus?.lastName && (
                    <S.TwoColumn>
                      <Input
                        disabled
                        name="firstName"
                        placeholder="First Name"
                        defaultValue={data?.customerBvnStatus?.firstName}
                      />
                      <Input
                        disabled
                        name="lastName"
                        placeholder="Last Name"
                        defaultValue={data?.customerBvnStatus?.lastName}
                      />
                    </S.TwoColumn>
                  )}

                <SubmitButton
                  disabled={
                    !values.bvn ||
                    !values.bvnPhoneDigits ||
                    !values.bvnDOB ||
                    (!data?.customerBvnStatus?.firstName &&
                      !data?.customerBvnStatus?.lastName)
                  }
                  type="submit"
                  value="Verify BVN"
                />

                <S.Aside>
                  NOTE: Dial *565*0# on your Bank registered mobile number to
                  get your BVN
                </S.Aside>
              </Form>
            );
          }}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default BvnVerification;
