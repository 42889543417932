/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import React from "react";
import * as S from "./styled";
import { Alert, Button, DataCard, Popup } from "../../../components";
import { Form, Formik } from "formik";
import Notifications from "react-notify-toast";
import {
  Input,
  NumberInput,
  SubmitButton,
} from "../../../components/FormikElements";
import {
  EditEmailValidationSchema,
  EmailOTPValidationSchema,
} from "../../../lib/validationSchemas";
import useEmailVerification from "./useEmailVerification";

const EmailVerification = ({ customForm }) => {
  const {
    applicationLoading,
    otpResendSuccess,
    emailError,
    email,
    alertSuccess,
    editEmailPopup,
    confirmEmailLoading,
    emailConfirmationLoading,
    handleSubmit,
    handleEmailEdit,
    openEditButtonPopup,
    resendEmailOTP,
    closeEditButtonPopup,
    updateEmailLoading,
  } = useEmailVerification({ customForm });

  const popUp = () => (
    <Popup title="Edit your e-mail" classes="edit-phone">
      <S.Subtitle>
        Please enter your email so we can send your verification OTP
      </S.Subtitle>
      <Formik
        initialValues={{
          email,
        }}
        validationSchema={EditEmailValidationSchema}
        onSubmit={(values) => {
          handleEmailEdit(values);
        }}
      >
        <Form>
          <Input name="email" placeholder="E-mail" type="email" />
          <SubmitButton value="Change e-mail" />
          <Button classes="secondary block" click_event={closeEditButtonPopup}>
            Cancel
          </Button>
        </Form>
      </Formik>
    </Popup>
  );

  const verifyWorkEmail = () => (
    <>
      <S.Title>Verify your e-mail</S.Title>
      <S.Subtitle>{`Please enter verification code sent to  ${
        email || ""
      }`}</S.Subtitle>
      {editEmailPopup && popUp()}
      {emailError && <Alert classes="error">{emailError}</Alert>}
      {otpResendSuccess && <Alert classes="success">{otpResendSuccess}</Alert>}
      {alertSuccess && <Alert classes="success">{alertSuccess}</Alert>}
      <Formik
        initialValues={{
          workEmailVerificationCode: "",
        }}
        validationSchema={EmailOTPValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <NumberInput
            name="workEmailVerificationCode"
            maxLength="6"
            placeholder="Verification Code"
          />
          <S.Paragraph className="center-text">
            Didn't get OTP? <br />
            <button type="button" onClick={() => resendEmailOTP()}>
              Resend OTP
            </button>{" "}
            /{" "}
            <button type="button" onClick={openEditButtonPopup}>
              {" "}
              Edit Email
            </button>
          </S.Paragraph>
          <SubmitButton value="Next" />
        </Form>
      </Formik>
    </>
  );

  return (
    <S.Wrapper>
      <Notifications />
      <DataCard
        loading={
          confirmEmailLoading ||
          emailConfirmationLoading ||
          applicationLoading ||
          updateEmailLoading
        }
      >
        {verifyWorkEmail()}
      </DataCard>
    </S.Wrapper>
  );
};

export default EmailVerification;
