/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from "react";
import { useLoanApplicationContext } from "../../../hooks";
import {  useMutation } from "@apollo/client";
import { CONFIRM_PHONE, TRIGGER_PHONE_CONFIRMATION } from "../mutations";
import { handleGraphQLErrors, maskPhoneNumber } from "../../../lib/utils";
import { pages } from "../../../lib/constants";
import { useHistory } from "react-router";

const usePhoneVerification = ({ customForm }) => {
  const {
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    applicationNumber,
    updateUserDetails,
    goToPreviousPage,
    goToNextPage,
    indexSlug,
    createApplicationTrace,
    setCompletedStep,
    loanApplicationIsLoading,
    user,
    userLoading:viewerLoading,
  } = useLoanApplicationContext();

  const [phoneError, setPhoneError] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(null);

  const history = useHistory();

  const getPath = () =>
    `/application-custom/${applicationNumber}`;

  const phone = user?.bvnStatus?.phone;
  const [phoneNumber, setPhoneNumber] = useState(phone);

  const [confirmPhone, { loading: confirmPhoneLoading }] = useMutation(
    CONFIRM_PHONE,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setPhoneError(errorMessage || "Unable to verify phone number.");
        hideAlert(setPhoneError);
      },
      onCompleted() {
        setCompletedStep("verifyPhone");
        if (customForm) {
          history.push(getPath());
        } else {
          goToNextPage();
        }
      },
    }
  );

  const [triggerPhoneConfirmation, { loading: phoneConfirmationLoading }] =
    useMutation(TRIGGER_PHONE_CONFIRMATION, {
      onError() {
        const errorMessage =
          "An error occurred. Please contact your support team for more information.";
        setPhoneError(
          errorMessage || "Unable to resend OTP. Please, try again later."
        );
        hideAlert(setPhoneError);
      },
      onCompleted({ triggerPhoneConfirmation }) {
        const { ok } = triggerPhoneConfirmation;
        if (!ok) setPhoneError("Unable to send OTP.");
        else {
          setAlertSuccess(`OTP sent to ${phoneNumber}`);
          hideAlert(setAlertSuccess);
        }
      },
    });

  const hideAlert = (state) => {
    setTimeout(() => {
      state(null);
    }, 5000);
  };

  const handleSubmit = useCallback(
    async ({ otp }) => {
      await confirmPhone({
        variables: {
          code: otp,
          userId: user?.id,
        },
      });
    },
    [user]
  );

  const resendOTP = useCallback(async () => {
    if (user?.id) {
      await triggerPhoneConfirmation({
        variables: {
          userId: user?.id,
          useBvnPhone: true,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (phone) {
      setPhoneNumber(phone);
    } 
    resendOTP();
  }, [phone]);

  useEffect(() => {
    if (phone) {
      createApplicationTrace(
        pages.phoneVerification,
        `Phone Number Verification Screen (${maskPhoneNumber(`0${phoneNumber || phone}`)})`
      );
    }
  }, [phone]);

  return {
    updateUserDetails,
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    goToNextPage,
    goToPreviousPage,
    indexSlug,
    createApplicationTrace,
    alertSuccess,
    phoneError,
    setAlertSuccess,
    phoneNumber,
    confirmPhoneLoading,
    phoneConfirmationLoading,
    handleSubmit,
    resendOTP,
    phone,
    loanApplicationIsLoading,
    viewerLoading,
  };
};

export default usePhoneVerification;
