import gql from 'graphql-tag';

const APPLICATION_FRAGMENT = gql`
  fragment ApplicationDetails on Application {
    id
    amount
    taxAmount
    baseAmount
    chargesAmount
    status {
      name
    }
    fullAmount
    loanDuration
    dateOfRepayment
    requiredSteps
    completedSteps
    bankAccount {
      id
      okraRecord {
        id
      }
    }
    createdAt
  }
`;

export const CONFIRM_PHONE = gql`
  mutation ($code: String!, $userId: ID!) {
    confirmPhone(input: { code: $code, userId: $userId }) {
      ok
    }
  }
`;

export const TRIGGER_PHONE_CONFIRMATION = gql`
  mutation ($userId: ID!, $useBvnPhone: Boolean) {
    triggerPhoneConfirmation(
      input: { userId: $userId, useBvnPhone: $useBvnPhone }
    ) {
      ok
    }
  }
`;

export const TRIGGER_BVN_CONFIRMATION = gql`
  mutation ($userId: ID!, $bvnPhoneDigits: String!) {
    triggerBvnPhoneConfirmation(
      input: { userId: $userId, bvnPhoneDigits: $bvnPhoneDigits }
    ) {
      ok
    }
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation ($userId: ID!, $phone: String!) {
    updatePhoneNumber(input: { userId: $userId, phone: $phone }) {
      ok
      record {
        isPhoneConfirmed
        phone
      }
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation (
    $address: String
    $state: String
    $localGovernment: String
    $dateMovedIn: DateTime
    $employmentStatus: String
    $employerName: String
    $workAddress: String
    $jobRole: String
    $workEmail: String
    $workStartDate: DateTime
    $netIncome: String
    $workId: String
    $selfie: String
    $refereeName: String
    $refereePhone: String
    $refereeWorkPlace: String
    $refereeEmail: String
    $facebookProfileUrl: String
    $socialMediaInformation: [SocialMediaInput]
  ) {
    updateUserData(
      input: {
        address: $address
        state: $state
        localGovernment: $localGovernment
        dateMovedIn: $dateMovedIn
        employmentStatus: $employmentStatus
        employerName: $employerName
        workAddress: $workAddress
        jobRole: $jobRole
        workEmail: $workEmail
        workStartDate: $workStartDate
        netIncome: $netIncome
        workId: $workId
        selfie: $selfie
        refereeName: $refereeName
        refereePhone: $refereePhone
        refereeWorkPlace: $refereeWorkPlace
        refereeEmail: $refereeEmail
        facebookProfileUrl: $facebookProfileUrl
        socialMediaInformation: $socialMediaInformation
      }
    ) {
      success
      records {
        id
        name
        value
      }
    }
  }
`;

export const AUTHENTICATE_CUSTOMER_FACEBOOK = gql`
  mutation ($accessToken: String!) {
    authenticateCustomerFacebook(input: { accessToken: $accessToken }) {
      ok
    }
  }
`;

export const TRIGGER_WORK_EMAIL_CONFIRMATION = gql`
  mutation ($clientId: ID!, $workEmail: String!) {
    triggerWorkEmailConfirmation(
      input: { clientId: $clientId, workEmail: $workEmail }
    ) {
      ok
    }
  }
`;

export const CONFIRM_WORK_EMAIL = gql`
  mutation ($code: String!, $workEmail: String!) {
    confirmWorkEmail(input: { code: $code, workEmail: $workEmail }) {
      ok
    }
  }
`;

export const TRIGGER_PERSONAL_EMAIL_CONFIRMATION = gql`
  mutation TriggerPersonalEmailConfirmation {
    triggerPersonalEmailConfirmation {
      ok
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($userId: ID!, $email: String!) {
    updateUserEmail(input: { userId: $userId, email: $email }) {
      ok
      record {
        isEmailConfirmed
      }
    }
  }
`;

export const CONFIRM_PERSONAL_EMAIL = gql`
  mutation ConfirmPersonalEmail($code: String!) {
    confirmPersonalEmail(code: $code) {
      ok
    }
  }
`;
export const COMPLETE_BANK_STATEMENT_REQUEST = gql`
  mutation (
    $applicationId: ID!
    $ticketNum: String
    $password: String
    $skipStep: Boolean
  ) {
    completeBankStatementRequest(
      input: {
        applicationId: $applicationId
        ticketNum: $ticketNum
        password: $password
        skipStep: $skipStep
      }
    ) {
      success
      application {
        id
        requiredSteps
        completedSteps
      }
    }
  }
`;

export const APPLICATION_CREDIT_SCORE = gql`
  mutation ($applicationId: ID!) {
    getApplicantCreditScore(input: { applicationId: $applicationId }) {
      success
      application {
        id
        requiredSteps
        completedSteps
        credit {
          score
        }
        status {
          name
        }
      }
    }
  }
`;

export const EXTERNAL_BANK_STATEMENT_REQUEST = gql`
  mutation CompleteExternalBankStatementRequest(
    $applicationId: ID!
    $ticketNum: String
    $password: String
    $skipStep: Boolean
  ) {
    completeExternalBankStatementRequest(
      input: {
        applicationId: $applicationId
        ticketNum: $ticketNum
        password: $password
        skipStep: $skipStep
      }
    ) {
      success
      application {
        id
        requiredSteps
        completedSteps
      }
    }
  }
`;

export const INITIATE_APPLICATIONS = gql`
  mutation InitiateApplication(
    $clientId: ID!
    $amount: Float!
    $loanDuration: Int!
    $dateOfRepayment: DateTime!
    $dayOfRepayment: Int
    $durationType: DurationType
    $loanCategoryId: ID
    $source: Source
  ) {
    initiateApplication(
      input: {
        clientId: $clientId
        amount: $amount
        loanDuration: $loanDuration
        dateOfRepayment: $dateOfRepayment
        dayOfRepayment: $dayOfRepayment
        durationType: $durationType
        loanCategoryId: $loanCategoryId
        source: $source
      }
    ) {
      success
      denied
      application {
        applicationNumber
        ...ApplicationDetails
      }
      errors {
        message
      }
      warnings {
        message
      }
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const SET_APPLICATION_BANKACCOUNT = gql`
  mutation SetApplicationBankAccount(
    $applicationId: ID!
    $accountBankId: ID!
    $skipBankStatementProvider: BankStatementProvider
  ) {
    setApplicationBankAccount(
      input: {
        applicationId: $applicationId
        accountBankId: $accountBankId
        skipBankStatementProvider: $skipBankStatementProvider
      }
    ) {
      success
      application {
        id
        amount
        taxAmount
        baseAmount
        chargesAmount
        fullAmount
        loanDuration
        dateOfRepayment
        requiredSteps
        completedSteps
        bankAccount {
          id
          okraRecord {
            id
          }
        }
      }
    }
  }
`;

export const INITIATE_BANK_STATEMENT_REQUEST = gql`
  mutation InitiateBankStatementRequest(
    $applicationId: ID!
    $accountBankId: ID!
    $isRetried: Boolean
  ) {
    initiateBankStatementRequest(
      input: {
        applicationId: $applicationId
        accountBankId: $accountBankId
        isRetried: $isRetried
      }
    ) {
      success
      application {
        id
        requiredSteps
        completedSteps
      }
    }
  }
`;

export const CONNECT_BANK_TO_OKRA = gql`
  mutation ConnectBankToOkra(
    $accountBankId: ID!
    $okraCustomerId: String!
    $okraRecordId: String!
    $okraAccountId: String
    $directDebitAuthorised: Boolean
  ) {
    connectBankToOkra(
      input: {
        accountBankId: $accountBankId
        recordId: $okraRecordId
        customerId: $okraCustomerId
        okraAccountId: $okraAccountId
        directDebitAuthorised: $directDebitAuthorised
      }
    ) {
      id
      accountName
      accountNumber
      status
      isDefault
      bank {
        id
        name
        code
        okraSlug
      }
      okraRecord {
        id
      }
    }
  }
`;

export const ADD_BANK_ACCOUNT = gql`
  mutation AddAccountBank(
    $bankId: ID!
    $accountName: String!
    $accountNumber: String!
    $okraCustomerId: String
    $okraRecordId: String
    $okraAccountId: String
    $directDebitAuthorised: Boolean
  ) {
    addAccountBank(
      input: {
        bankId: $bankId
        accountName: $accountName
        accountNumber: $accountNumber
        okraCustomerId: $okraCustomerId
        okraRecordId: $okraRecordId
        okraAccountId: $okraAccountId
        directDebitAuthorised: $directDebitAuthorised
      }
    ) {
      id
      status
      isDefault
    }
  }
`;

export const COMPLETE_APPLICATION = gql`
  mutation CompleteApplication($applicationId: ID!) {
    completeApplication(input: { applicationId: $applicationId }) {
      approved
      errors {
        message
      }
      warnings {
        message
      }
      portfolio {
        id
      }
      application {
        ...ApplicationDetails
      }
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const GENERATE_APPLICATION_OFFERS = gql`
  mutation GenerateApplicationOffers($applicationId: ID!) {
    generateApplicationOffers(input: { applicationId: $applicationId }) {
      application {
        ...ApplicationDetails
      } 
      potentialOffer {
        id
        baseAmount
        fullAmount
        interestRate
        loanTenor
        policy {
          id
          processingFee {
            calcBy
            name
            type
            value
          }
          interestRate {
            value
          }
          processingFeeDebitMethod
        }
        repaymentBreakdown {
          principalBalance
          expectedPayment
          interestPortion
          principalPortion
          endingBalance
          dueDate
        }
        repaymentType
      }
      suggestedOffer {
        id
        data {
          fullAmount
          baseAmount
          noOfRepayments
          chargesAmount
          repaymentBreakdown {
            principalBalance
            expectedPayment
            interestPortion
            principalPortion
            endingBalance
            dueDate
          }
        }
        policy {
          id
          processingFee {
            value
            name
            type
            calcBy
          }
          interestRate {
            value
          }
          processingFeeDebitMethod
        }
      }
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const CREATE_CONTEXT_STATE = gql`
  mutation CreateContextState(
    $state: JSON
    $context: ContextType!
    $page: String
  ) {
    createContextState(
      input: { state: $state, context: $context, page: $page }
    ) {
      id
      page
      state
    }
  }
`;

export const UPDATE_CONTEXT_STATE = gql`
  mutation updateContextState($id: ID!, $state: JSON, $page: String) {
    updateContextState(input: { id: $id, state: $state, page: $page }) {
      id
      state
      page
    }
  }
`;

export const CREATE_APPLICATION_TRACE = gql`
  mutation (
    $applicationId: ID!
    $isDebug: Boolean
    $metadata: JSON
    $page: String
    $comment: String!
  ) {
    createApplicationTrace(
      input: {
        applicationId: $applicationId
        isDebug: $isDebug
        metadata: $metadata
        page: $page
        comment: $comment
      }
    ) {
      success
      trace {
        id
        page
        comment
      }
    }
  }
`;

export const UPLOAD_BANK_STATEMENT = gql`
  mutation UploadBankStatement(
    $file: Upload!
    $password: String
    $bankId: String!
    $applicationId: ID!
  ) {
    uploadBankStatement(
      input: {
        file: $file
        password: $password
        bankId: $bankId
        applicationId: $applicationId
      }
    ) {
      filename
      mimetype
      encoding
    }
  }
`;

export const SKIP_CARD_COLLECTION = gql`
  mutation skipCardCollection($applicationId: ID!) {
    skipCardCollection(input: { applicationId: $applicationId }) {
      success
      application {
        id
      }
    }
  }
`;
