import React, { Fragment, useEffect, useRef, useState } from 'react';
import { notify } from 'react-notify-toast';
import PaystackButton from 'react-paystack';
import { Alert, BankCard, Button, DebitCard, Loader } from '../../components';
import { getClientId, useClientInfoContext } from '../../hooks';
import Constants from '../../lib/constants';
import { BankService, CardService } from '../../services';
import { PaymentInformationProvider } from '../providers';
import './_Settings.scss';

const { status, duration } = Constants;

const PaymentSettings = () => {
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const [changeDefaultBankComplete, setChangeDefaultBankComplete] =
    useState(false);

  const [payStackReferenceStatusError, setPayStackReferenceStatusError] =
    useState(null);
  const [channel] = useState(['card']);
  const clientId = getClientId();
  const [refetchBankCards, setRefetchBankCards] = useState(false);
  const {
    clientInfo: { paystackPubKey, noCardImplementation, addCardCharge },
  } = useClientInfoContext();
  const referenceFetched = useRef(false);

  const getReference = async () => {
    setLoading(true);

    const response = await CardService.getAddCardReference(clientId);

    if (response && response.status === 200 && response.data.data != null) {
      const { getAddCardReference } = response.data.data;

      if (getAddCardReference && getAddCardReference.reference) {
        setReference(getAddCardReference.reference);
        setLoading(false);
        return getAddCardReference.reference;
      }
    }
  };

  const getReferenceStatus = async payStackReference => {
    setLoading(true);
    let ref = payStackReference;

    if (payStackReference.reference) {
      ref = payStackReference.reference;
    } else if (reference) {
      ref = reference;
    }

    const response = await CardService.getCardReferenceStatus({
      reference: ref,
    });

    if (!response || !response.data || !response.data.data) {
      const errorMessage =
        response.data.errors[0].message ||
        `There was a problem getting card reference status, Please try again later`;
      setPayStackReferenceStatusError(errorMessage);
      notify.show(errorMessage, 'error', 8000);
      return;
    }

    if (response && response.status === 200) {
      const { getCardReferenceStatus } = response?.data?.data;
      const payStackReferenceStatus = getCardReferenceStatus.status;

      switch (payStackReferenceStatus) {
        case 'SUCCESS':
          notify.show(
            'Your card has been added successfully.',
            status.SUCCESS,
            duration.LONG,
          );
          setRefetchBankCards(true);
          break;
        case 'FAILED':
          notify.show(
            `${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`,
            'error',
            8000,
          );
          break;
        case 'NOT_USED':
        case 'ABANDONED':
          notify.show(
            `To add a card, please proceed by clicking the add new card button`,
            'info',
            8000,
          );
          break;
        case 'INVALID':
          notify.show(
            `${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`,
            'info',
            8000,
          );
          break;
        case 'NOT_REUSABLE':
          notify.show(
            `Your card can not be used. Please, add a new card`,
            'error',
            8000,
          );
          break;
      }

      setLoading(false);
    }
  };

  const addCard = async () => {
    setLoading(true);
    const generatedReference = await getReference();

    if (generatedReference) {
      const payStack = document.querySelector('.psButton');
      payStack.click();
    } else {
      setLoading(false);
      notify.show(
        'There was an error generating your reference. Please, refresh the page and try again later.',
        status.ERROR,
        duration.LONG,
      );
    }
  };

  const closePaystackPopup = () => {
    notify.show(
      'You have cancelled add new card.',
      status.ERROR,
      duration.LONG,
    );
  };

  const setDefaultBank = async (accountId, bankAccount) => {
    setLoading(true);

    const accountBankId = bankAccount.id;

    const response = await BankService.setDefaultAccountBank(
      accountId,
      accountBankId,
    );
    if (!response) {
      setLoading(false);
      notify.show(
        'There was an error changing your default bank. Please try again later',
        status.ERROR,
        duration.LONG,
      );
    }

    if (response && response.status === 200 && response.data.data != null) {
      setLoading(false);
      const { setDefaultAccountBank } = response.data.data;
      if (setDefaultAccountBank) {
        notify.show(
          'Your default account has been successfully changed',
          status.SUCCESS,
          duration.LONG,
        );
        setChangeDefaultBankComplete(setDefaultAccountBank);
      } else {
        notify.show(
          'There was an error changing your default bank. Please try again later',
          status.ERROR,
          duration.LONG,
        );
      }
    }
  };

  useEffect(() => {
    if (!reference && !referenceFetched.current) {
      referenceFetched.current = true;
      getReference().then((newReference) => {
        if (newReference) setReference(newReference);
      });
    }
  }, []);

  return (
    <PaymentInformationProvider>
      {({ cards, bankAccounts, email, accountId, error, refetchCards }) => {
        if (changeDefaultBankComplete || refetchBankCards) {
          refetchCards();
        }
        return (
          <section className={'container'}>
            {loading && <Loader />}
            {error && (
              <Alert classes="error">
                There was an error loading cards and banks. Please reload the
                page
              </Alert>
            )}

            {payStackReferenceStatusError && (
              <Alert classes="error">{payStackReferenceStatusError}</Alert>
            )}

            <div className={'payment-options'}>
              {noCardImplementation ? (
                ''
              ) : (
                <section>
                  {cards.length === 0 ? (
                    <Alert classes="info">
                      You have not added any cards yet.
                    </Alert>
                  ) : (
                    <section>
                      <h3>Cards</h3>
                      <p className={'payment-options__subtitle'}>
                        Your repayments can be charged to your active card.
                      </p>
                      {cards?.map((card, index) => (
                        <DebitCard
                          cardDetails={card}
                          accountId={accountId}
                          key={index}
                        />
                      ))}
                    </section>
                  )}

                  {!loading && !reference && (
                    <section className='referenceError'>
                      There was an error generating payment reference.
                      <button
                        className='retry'
                        onClick={() => {
                          getReference()
                        }}
                      >
                        Please, retry.
                      </button>
                    </section>
                  )}

                  {paystackPubKey && (
                    <Fragment>
                      <Button
                        button_text="Add New Card"
                        classes="add-card-btn"
                        click_event={addCard}
                        disable={!reference}
                      />

                      <PaystackButton
                        text="Add Card"
                        class="PayStackButton button psButton hidden"
                        embed={false}
                        reference={reference}
                        email={email}
                        close={closePaystackPopup}
                        amount={parseInt(addCardCharge || 1) * 100}
                        paystackkey={paystackPubKey}
                        callback={getReferenceStatus}
                        tag="button"
                        channels={channel}
                      />
                    </Fragment>
                  )}
                </section>
              )}
              <section>
                {bankAccounts.length === 0 ? (
                  <Alert classes="info">
                    You have not added any bank accounts yet.
                  </Alert>
                ) : (
                  <section>
                    <h3>Bank Accounts</h3>
                    <p className={'payment-options__subtitle'}>
                      This is used for loan disbursements.
                    </p>

                    {bankAccounts?.map((bankAccount, index) => (
                      <BankCard
                        active={bankAccount?.isDefault}
                        key={index}
                        on_click={() => setDefaultBank(accountId, bankAccount)}
                      >
                        <div>
                          <div className={'account-number'}>
                            {bankAccount?.accountNumber}
                          </div>
                          <div className={'bank'}>
                            <span className={'account-name'}>
                              {bankAccount?.accountName}
                            </span>
                            <span className={'bank-name'}>
                              {bankAccount?.bank?.name}
                            </span>
                          </div>
                        </div>
                      </BankCard>
                    ))}
                  </section>
                )}
              </section>
            </div>
          </section>
        );
      }}
    </PaymentInformationProvider>
  );
};

export default PaymentSettings;
