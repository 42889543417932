import * as S from '../styled';
import { Form, Formik } from 'formik';
import { Input, SubmitButton } from '../../../../components/FormikElements';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, DataCard } from '../../../../components';
import useMbsForm from '../useMbsForm';
import { APPLICATION_STEPS } from '../../../../lib/applicationSteps';
import { ListItem, UnorderedList } from '@chakra-ui/react';

const MbsOtp = ({ customForm }) => {
  const {
    handleTicketSubmit,
    resendMBSOTP,
    handleSkip,
    redirectToExternal,
    application,
    startBankStatementRequest,
    loading,
    applicationLoading,
    applicationBank,
  } = useMbsForm({ customForm });

  const { requiredSteps, completedSteps } = application?.application || {};
  const [isFromExternal, setIsFromExternal] = useState(false);

  useEffect(() => {
    if (!requiredSteps) return;
  
    const initiateStep = APPLICATION_STEPS.initiateBankStatementRequest.name;
    const completeExternalStep =
      APPLICATION_STEPS.completeExternalBankStatementRequest.name;
  
    const shouldInitiate =
      requiredSteps.includes(initiateStep) &&
      !completedSteps?.includes(initiateStep);
    if (shouldInitiate && applicationBank?.bank?.id) {
      startBankStatementRequest();
    }
  
    const shouldSetExternal =
      requiredSteps.includes(completeExternalStep) &&
      !completedSteps?.includes(completeExternalStep);
    if (shouldSetExternal) {
      setIsFromExternal(true);
    }
  }, [requiredSteps, completedSteps, applicationBank?.bank?.id]);
  
  const [otpTimer, setOtpTimer] = useState(true);
  const [skipMbsTimer, setSkipMbsTimer] = useState(true);

  const useCountDown = start => {
    const [counter, setCounter] = useState(start);
    useEffect(() => {
      if (counter === 0) {
        setOtpTimer(false);
        setTimeout(() => {
          setSkipMbsTimer(false);
        }, 45000);
        return;
      }
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }, [counter]);
    return counter;
  };

  const Countdown = ({ seconds }) => {
    const timeLeft = useCountDown(seconds);
    return (
      <S.Div>
        Resend OTP in <span className="timer">00:{timeLeft}s</span>
      </S.Div>
    );
  };

  return (
    <S.Wrapper>
      <DataCard loading={loading || applicationLoading}>
        <S.Title>
          Enter the Ticket Number and OTP/Password sent by your bank. (Check
          Email/SMS)
        </S.Title>
          <UnorderedList>
            <ListItem>Account Number: <strong>{applicationBank?.accountNumber}</strong></ListItem>
            <ListItem>Account Name: <strong>{applicationBank?.accountName}</strong></ListItem>
            <ListItem>Bank: <strong>{applicationBank?.bank?.name}</strong></ListItem>
          </UnorderedList>

        <Formik
          initialValues={{
            ticketNum: '',
            password: '',
          }}
          onSubmit={values => {
            handleTicketSubmit({
              ...values,
              applicationId: application?.application?.id,
            });
          }}
        >
          {({ values }) => (
            <>
              <Form>
                <Fragment>
                  <Input
                    name="ticketNum"
                    placeholder="Ticket Number: 123456-7"
                    disabled={values.skipStep === true}
                  />
                  <Input
                    name="password"
                    placeholder="Password: 1234"
                    disabled={values.skipStep === true}
                  />
                  {otpTimer ? (
                    <Countdown seconds={59} />
                  ) : (
                    <div>
                      <p>Didn't get Ticket Number/OTP sent by your Bank?</p>
                      <Button classes="button-link" click_event={resendMBSOTP}>
                        Resend OTP
                      </Button>
                    </div>
                  )}
                </Fragment>
                <SubmitButton value="Next" />
                 {!skipMbsTimer && ( 
                  <S.SkipButton
                    type="button"
                    onClick={handleSkip}
                    value="Skip to Upload Bank Statement"
                  />
               )} 
               
                {isFromExternal && (
                  <S.SkipButton
                    type="button"
                    onClick={redirectToExternal}
                    value="Back"
                  />
                )}
              </Form>
            </>
          )}
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default MbsOtp;
