import React, { Fragment, useRef, useState } from 'react';
import './_DebitCard.scss';
import Radio from '../FormElements/Radio/Radio';
import CardService from '../../services/cardService';
import { Loader } from '../index';
import { notify } from 'react-notify-toast';
import Constants from '../../lib/constants';
import MasterCard from '../Icon/MasterCard';
import VisaCard from '../Icon/VisaCard';
import VerveCard from '../Icon/VerveCard';
import * as S from './styled';
const { status, duration } = Constants;

const CardMaskPan = ({ maskedPan }) => {
  return (
    <S.MaskPan>
      <span>{maskedPan.substring(0, 4)}</span>
      <div>
        <span>{maskedPan.substring(4, 6)}</span>
        <span className="circle" />
        <span className="circle" />
      </div>
      <div>
        {[1, 2, 3, 4].map((_, i) => (
          <span className="circle" key={i} />
        ))}
      </div>
      <span>{maskedPan.slice(-4)}</span>
    </S.MaskPan>
  );
};

const DebitCard = ({ cardDetails, accountId, on_click, active, bankName, setPaymentType }) => {
  const { maskedPan, expiryDate, type, id, isDefault } = cardDetails;
  const selectedDebitCardRef = useRef();

  const [loading, setLoading] = useState(false);

  const setDefaultCard = async () => {
    setLoading(true);
    const response = await CardService.setDefaultAccountCard(id, accountId);

    if (response && response.status === 200 && response.data.data != null) {
      const { setDefaultAccountCard } = response.data.data;

      if (setDefaultAccountCard && setDefaultAccountCard.status === 'active') {
        notify.show(
          'Your primary card has been updated.',
          status.SUCCESS,
          duration.LONG,
        );
        setLoading(false);
        return;
      }
    }

    setLoading(false);
    notify.show(
      'There was an error updating your Primary card. Please, try again later.',
      status.ERROR,
      duration.LONG,
    );
  };

  return (
    <Fragment>
      <S.StyledDebitCard
        className="debit-card"
        onClick={
          on_click
            ? on_click
            : () => {
                setPaymentType("card")
                selectedDebitCardRef?.current?.click();               
              }
        }
        clickable={!!on_click}
      >
        <section className="debit-card__top">
          <section className="debit-card__status">
            {on_click ? (
              <S.CustomTop active={active}>
                <div className="circle" />
                {active && (
                  <span className="left_label">THIS CARD IS ACTIVE</span>
                )}
                <span className="bank_name">{bankName}</span>
              </S.CustomTop>
            ) : (
              <Radio
                name="defaultCard"
                ref={selectedDebitCardRef}
                onChange={setDefaultCard}
                defaultChecked={isDefault}
                label={'Repayment Card'}
              />
            )}
          </section>
        </section>
        {on_click ? (
          <CardMaskPan maskedPan={maskedPan || ''} />
        ) : (
          <section className="debit-card__pan">
            <span className="debit-card__pan-mask" />
            <span className="debit-card__pan-mask" />
            <span className="debit-card__pan-mask" />
            <span>{maskedPan.slice(-4)}</span>
          </section>
        )}
        <section className="debit-card__bottom">
          {expiryDate && (
            <section className="debit-card__expiry">
              Expires: <em>{expiryDate.replace('-', '/')}</em>
            </section>
          )}
          <section className="debit-card__type">
            {(() => {
              switch (type) {
                case 'mastercard':
                  return <MasterCard />;
                case 'visa':
                  return <VisaCard />;
                case 'verve':
                  return <VerveCard />;
                default:
                  return '';
              }
            })()}
          </section>
        </section>
      </S.StyledDebitCard>
      {loading && <Loader />}
    </Fragment>
  );
};

export default DebitCard;
