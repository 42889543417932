import { useCallback, useEffect, useState } from 'react';
import { useCustomForm } from '../containers/Application/CustomFormApplication/useCustomForm';
import { NEW_LOAN_ROUTES } from '../containers/LoanApplication/routes';
import { APPLICATION_STEPS } from '../lib/applicationSteps';
import { useRouteMatch } from 'react-router-dom';
import { GET_DECIDE_JOB_STATUS } from '../containers/LoanApplication/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SINGLE_APPLICATION_QUERY } from '../containers/providers/User/queries';

const bankStatementDoneSteps = [
  APPLICATION_STEPS.uploadBankStatement.name,
  APPLICATION_STEPS.completeBankStatementRequest.name,
  APPLICATION_STEPS.completeExternalBankStatementRequest.name,
];

const useCustomApplicationRoute = () => {
  const [isNavigating, setIsNavigating] = useState(false);

  
  const { formIndex, applicationForm } = useCustomForm();
  const { params: { applicationNumber } } = useRouteMatch();
    // New state to track when the user clicks the "Skip" button on the Add Card step.
    const [hasSkippedCard, setHasSkippedCard] = useState(false);

  const [getApplication, { data, refetch }] = useLazyQuery(
    SINGLE_APPLICATION_QUERY,
    {
      variables: { applicationNumber },
      fetchPolicy: 'network-only',
    }
  );

  const application = data?.application;

  const ticketNumber = application?.credit?.ticketNo

  const extractAttributes = () => {
    const result = {};
    const namesSet = new Set([
      "requiresPhoneVerification",
      "allowCardSkipDuringApplication"
    ]);
    
    application?.loanCategory?.loanCategoryAttributes?.forEach(item => {
      const attrName = item?.attribute?.name;
      if (namesSet.has(attrName)) {
        const rawValue = item?.value;
        // Handle null values
        if (rawValue === null) {
          result[attrName] = null;
        } 
        // Handle boolean strings
        else if (rawValue === "true") {
          result[attrName] = true;
        } 
        else if (rawValue === "false") {
          result[attrName] = false;
        } 
        // Handle numeric strings
        else if (!isNaN(rawValue) && rawValue !== "") {
          result[attrName] = Number(rawValue);
        } 
        // Keep other values as is
        else {
          result[attrName] = rawValue;
        }
      }
    });
    
    return result;
  };

  const applicationAttributes = extractAttributes();

  const { data: decideJobData } = useQuery(GET_DECIDE_JOB_STATUS, {
    variables: { input: { applicationId: application?.id } },
    skip: !application?.id,
  });

  useEffect(() => {
    getApplication();
  }, [applicationNumber, getApplication]);

  const activeFormTabs = applicationForm?.filter((tab) => !tab.linkedToOption);
  const outOfTabs = activeFormTabs?.length ? formIndex >= activeFormTabs.length : false;

  const normalizePath = useCallback((path) => path?.replace(/\/+$/, ''), []);

  /**
   * getAllowedRoute refetches the application first so that all routing conditions use
   * the latest data. It then rebuilds values (such as the completed steps map and
   * condition functions) from the refreshed application data.
   */
  const getAllowedRoute = useCallback(async () => {
    setIsNavigating(true);
    try {
      // Refetch the application and extract the latest data.
      const refreshedResult = await refetch();
      const updatedApplication = refreshedResult?.data?.application || application;

      // Build a fresh lookup for completed steps.
      const completedStepsHashMap =
        updatedApplication?.completedSteps?.reduce((map, step) => {
          map[step] = true;
          return map;
        }, {}) || {};

      // Helper to check if a given step is pending.
      const isApplicationStepPending = (step) =>
        updatedApplication?.requiredSteps?.includes(step) &&
        !updatedApplication?.completedSteps?.includes(step);

      // Condition functions based on the refreshed data.
      const pdfJobIsDone = ['DONE', 'SKIPPED'].includes(
        decideJobData?.getDecidePdfStatus?.status
      );

      const shouldUploadBankStatement = () => {
        if (
          bankStatementDoneSteps.some((step) => !!completedStepsHashMap[step]) &&
          !pdfJobIsDone
        ) {
          return false;
        }
        return isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name);
      };

      const shouldSelectBankAccount = () => {
        if (!updatedApplication?.bankAccount?.id) return true;
        return isApplicationStepPending(APPLICATION_STEPS.setBankAccount.name);
      };

      const shouldShowExternalMbs = () => {
        if (
          bankStatementDoneSteps.some((step) => !!completedStepsHashMap[step]) ||
          isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name)
        ) {
          return false;
        }
        const isPending = Boolean(localStorage.getItem('passedExternalMbsInstruction'));
        const showExternalMbs = !isPending
        const requiresBankStatementStep = application?.requiredSteps?.some(step => 
          step === "COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST"
        );
        return requiresBankStatementStep && !ticketNumber && showExternalMbs
      };

      const shouldShowMbsOtp = () => {
        if (
          bankStatementDoneSteps.some((step) => !!completedStepsHashMap[step]) ||
          isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name)
        ) {
          return false;
        }
   
        const requiresBankStatementStep = application?.requiredSteps?.some(step => 
            step === "INITIATE_BANK_STATEMENT_REQUEST" ||
            step === "COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST"
        );

        return !ticketNumber && requiresBankStatementStep; 
      };

      const isEmailConfirmed = updatedApplication?.user?.isEmailConfirmed;
      const isPhoneConfirmed = updatedApplication?.user?.isPhoneConfirmed;
      const account = updatedApplication?.account || {};

      const shouldVerifyEmail = () => !isEmailConfirmed;
      const shouldVerifyPhone = () =>
        (isPhoneConfirmed == null || !isPhoneConfirmed);
      // Uncomment this to add back the condition
      // && applicationAttributes?.requiresPhoneVerification;

      // Version of getRouteForStep using the refreshed data.
      const getRouteForStep = (stepType) => {
        const stepConfig = {
          VERIFY_EMAIL: {
            applicationStep: 'verifyEmail',
            condition: shouldVerifyEmail,
            route: NEW_LOAN_ROUTES.verifyEmail,
          },
          VERIFY_PHONE: {
            applicationStep: 'verifyPhone',
            condition: shouldVerifyPhone,
            route: NEW_LOAN_ROUTES.verifyPhone,
          },
          FORM_PROGRESS: {
            applicationStep: 'formProgress',
            condition: () => !outOfTabs,
            route: '',
          },
          ADD_CARD: {
            applicationStep: 'addCard',
            condition: () => {
              // If the user has chosen to skip the add card page, do not display it.
              if (hasSkippedCard) return false;
              // Otherwise, display the add card page only if there are no cards.
              return (!account?.cards || account.cards.length < 1);
            },
            route: NEW_LOAN_ROUTES.addCard,
          },
          BANK_ACCOUNT: {
            applicationStep: APPLICATION_STEPS.setBankAccount.name,
            condition: shouldSelectBankAccount,
            route: NEW_LOAN_ROUTES.bankAccount,
          },
          EXTERNAL_MBS: {
            applicationStep: APPLICATION_STEPS.completeExternalBankStatementRequest.name,
            condition: shouldShowExternalMbs,
            route: NEW_LOAN_ROUTES.mbsInstruction,
          },
          MBS_OTP: {
            applicationStep: APPLICATION_STEPS.initiateBankStatementRequest.name,
            condition: shouldShowMbsOtp,
            route: NEW_LOAN_ROUTES.mbsOtp,
          },
          UPLOAD_BANK_STATEMENT: {
            applicationStep: APPLICATION_STEPS.uploadBankStatement.name,
            condition: shouldUploadBankStatement,
            route: NEW_LOAN_ROUTES.uploadBankStatement,
          },
        };

        const config = stepConfig[stepType];
        if (!config) return undefined;

        // Skip if the step was already completed.
        if (config.applicationStep && completedStepsHashMap[config.applicationStep] &&
          stepType !== 'MBS_OTP' &&
          stepType !== 'EXTERNAL_MBS') {
          return undefined;
        }
        // Only return a route if the condition for the step is met.
        if (config.condition && !config.condition()) {
          return undefined;
        }
        return config.route === ''
          ? `/application-custom/${applicationNumber}`
          : `/application-custom/${applicationNumber}/${config.route}`;
      };

      // Define the step sequence to check.
      const stepSequence = [
        'VERIFY_EMAIL',
        'VERIFY_PHONE',
        'FORM_PROGRESS',
        'ADD_CARD',
        'BANK_ACCOUNT',
        'EXTERNAL_MBS',
        'MBS_OTP',
        'UPLOAD_BANK_STATEMENT',
      ];

      // Loop over each step in sequence until a valid route is found.
      for (const stepType of stepSequence) {
        const route = getRouteForStep(stepType);
        if (route) return route;
      }

      // Fallback route if none of the conditions match.
      return `/application-custom/${applicationNumber}/${NEW_LOAN_ROUTES.confirmLoan}`;
    } finally {
      setIsNavigating(false);
    }
  }, [application, applicationNumber, decideJobData, outOfTabs, refetch, hasSkippedCard]);

  // This can be used by a component to trigger a skip on the Add Card page.
  const skipAddCard = applicationAttributes?.allowCardSkipDuringApplication;

  return { getAllowedRoute, normalizePath, isNavigating, skipAddCard, setHasSkippedCard };
};

export default useCustomApplicationRoute;
