import React, { Fragment, useState, useEffect, useRef } from 'react';
import { notify } from 'react-notify-toast';
import PaystackButton from 'react-paystack';
import { withRouter } from 'react-router';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import {
  Alert,
  Button,
  DataCard,
  DebitCard,
  FormGroup,
  Input,
  Loader,
  Notification,
  Radio,
  RadioGroup,
} from '../../components';
import {
  CheckIcon,
  NairaAmount,
  NewLoanIcon,
  SadSmiley,
} from '../../components/Icon';
import { getClientId, useClientInfoContext } from '../../hooks';
import Constants from '../../lib/constants';
import { numberWithCommas, removeCommas } from '../../lib/utils';
import CardService from '../../services/cardService';
import {
  MakePaystackCardRepaymentProvider,
  MakePaystackReferenceRepaymentProvider,
  PaymentInformationProvider,
  ViewerProvider,
} from '../providers';
import './_MakeRepayment.scss';
const { status, duration } = Constants;
const cachedClientId = getClientId();

const StyledNewCard = styled.div`
  .new-card {
    border-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryColor};

    .button-text {
      color: ${props => props.theme.primaryColor};
    }

    svg {
      fill: ${props => props.theme.primaryColor};
    }
  }
`;

const MakeRepayment = props => (
  <ViewerProvider>
    {({ currentLoan }) => (
      <MakePaystackCardRepaymentProvider>
        {({ makePaystackCardRepayment }) => (
          <PaymentInformationProvider>
            {({ cards, accountId, email }) => {
              return (
                <MakeRepaymentWrapper
                  {...props}
                  makeRepayment={makePaystackCardRepayment}
                  currentLoan={currentLoan}
                  accountId={accountId}
                  cards={cards}
                  email={email}
                />
              );
            }}
          </PaymentInformationProvider>
        )}
      </MakePaystackCardRepaymentProvider>
    )}
  </ViewerProvider>
);

const MakeRepaymentWrapper = ({
  makeRepayment,
  currentLoan,
  cards,
  accountId,
  email,
}) => {
  const {
    clientInfo: { paystackPubKey, noCardImplementation, clientId },
  } = useClientInfoContext();
  const [loading, setLoading] = useState(false);
  const [repaymentComplete, setRepaymentComplete] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reference, setReference] = useState('');
  const { makeReferenceRepayment, makeReferenceRepaymentError } =
    MakePaystackReferenceRepaymentProvider();
  const [amountPaid, setAmountPaid] = useState(0);
  const [channel] = useState(['card', 'bank_transfer']);
  const selectedRepaymentMethodRef = useRef();
  const referenceFetched = useRef(false);
  const [paymentType, setPaymentType] = useState("card");

  const [formState, { text, radio }] = useFormState({
    portfolioId: currentLoan.id,
    amountType: 'full',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error, errorMessage]);

  const nextInstallment =
    (currentLoan.repayments &&
      currentLoan.repayments.find(
        repayment => repayment.status.name === 'PENDING',
      )) ||
    {};

  const getRepaymentAmount =
    formState.values.amountType === 'full'
      ? currentLoan.fullAmount - currentLoan.amountPaid
      : formState.values.amountType === 'nextInstallment'
      ? nextInstallment.outstandingPayment || currentLoan.fullAmount
      : formState.values.amount;

  const makeCardOrManualRepayment = () => {
    setError(false);
    setErrorMessage('');

    if (
      paymentType === "payWtDiffMethod"
    ) {
      addCard();
    } else {
      makeManualRepayment();
    }
  };

  const makeManualRepayment = async () => {
    let { amount, portfolioId, amountType } = formState.values;

    const defaultCard = cards.find(card => card.isDefault === true);

    if (amountType === 'full') {
      amount = currentLoan.fullAmount - currentLoan.amountPaid;
    }

    if (amountType === 'nextInstallment') {
      amount = nextInstallment.outstandingPayment || currentLoan.fullAmount;
    }

    const payload = {
      accountCardId: defaultCard.id,
      amount,
      portfolioId,
    };

    setLoading(true);
    setError(false);
    setErrorMessage('');

    const response = await makeRepayment(payload);
    setLoading(false);

    if (!response) {
      setError(true);
      return;
    }

    if (response?.errors) {
      setErrorMessage(response?.errors?.message);
      setError(true);
    }

    if (response.data.errors) {
      setError(true);
      return;
    }

    const { makePaystackCardRepayment } = response.data;

    if (!makePaystackCardRepayment) {
      setError(true);
      return;
    }

    if (!makePaystackCardRepayment?.success) {
      setErrorMessage(
        makePaystackCardRepayment?.message ||
          'There was an error making your repayment. Please, try again later.',
      );
      setError(true);
      return;
    }

    if (makePaystackCardRepayment?.transaction?.amount) {
      setAmountPaid(makePaystackCardRepayment.transaction.amount);
    }

    notify.show('Transaction successful', 'success', 600);

    setRepaymentComplete(true);
  };

  const getReference = async () => {
    setLoading(true);

    const response = await CardService.getAddCardReference(cachedClientId || clientId);

    if (response && response.status === 200 && response.data.data != null) {
      const { getAddCardReference } = response.data.data;

      if (getAddCardReference && getAddCardReference.reference) {
        setReference(getAddCardReference.reference);

        setLoading(false);
        return getAddCardReference.reference;
      }
    }
  };

  const addCard = async () => {
    setLoading(true);
    const generatedReference = await getReference();

    if (!generatedReference) {
      setLoading(false);
      notify.show(
        'There was an error generating your reference. Please, refresh the page and try again later.',
        status.ERROR,
        duration.LONG,
      );
    }
  };

  const makePaystackReferenceRepayment = async () => {
    setLoading(true);
    setError(false);
    setErrorMessage('');

    const response = await makeReferenceRepayment(reference);
    setLoading(false);
    if (!response) {
      setError(true);
      return;
    }

    if (response?.errors) {
      setErrorMessage(response?.errors?.message);
      setError(true);
    }

    if (response.data.errors) {
      setError(true);
      return;
    }

    const { makePaystackReferenceRepayment } = response.data;

    if (!makePaystackReferenceRepayment) {
      setError(true);
      return;
    }

    if (!makePaystackReferenceRepayment.success) {
      return;
    }

    if (makePaystackReferenceRepayment?.success) {
      if (makePaystackReferenceRepayment?.transaction?.amount) {
        setAmountPaid(makePaystackReferenceRepayment.transaction.amount);
      }

      notify.show('Transaction successful', 'success', 600);

      setRepaymentComplete(true);

      return;
    }
  };

  const paystackMetadata = {
    accountId,
    portfolioId: currentLoan.id,
    transactionType: Constants.transactType.repaymentTransaction,
  };

  const closePaystackPopup = () => {
    notify.show(
      'You have cancelled the repayment.',
      status.ERROR,
      duration.LONG,
    );
  };

  useEffect(() => {
    if (!reference && !referenceFetched.current) {
      referenceFetched.current = true;
      getReference().then((newReference) => {
        if (newReference) setReference(newReference);
      });
    }
  }, []);

  return (
    <ViewerProvider>
      {({ currentLoan }) =>
        currentLoan.portfolioNumber ? (
          <section className="container offline-repayment">
            <Fragment>
              {noCardImplementation ? (
                <section className="container no-loan">
                  <DataCard>
                    <div className="smiley-holder">
                      <SadSmiley />
                    </div>
                    <h3 className="center-text">
                      Sorry, you're not allowed to access this page.
                    </h3>
                    <Button
                      click_event={() => {
                        window.location.href = '/dashboard';
                      }}
                    >
                      Go to your Dashboard
                    </Button>
                  </DataCard>
                </section>
              ) : (
                <Fragment>
                  {loading && <Loader />}
                  {!repaymentComplete ? (
                    <DataCard>
                      <form>
                        <h3 className="center-text">Make Repayment</h3>

                        {(error || errorMessage) && (
                          <Alert classes="error">
                            {makeReferenceRepaymentError ||
                              errorMessage ||
                              `There was an error making your repayment. Please, try
                          again later.`}
                          </Alert>
                        )}

                        <p className="lead-text bold-text">
                          Choose Repayment Amount
                        </p>
                        <RadioGroup classes="early-repayment">
                          <Radio
                            label="Repay Full Loan"
                            {...radio('amountType', 'full')}
                          />
                          <Radio
                            label={`Repay Next Installment - ${numberWithCommas(
                              (nextInstallment &&
                                nextInstallment.outstandingPayment) ||
                                currentLoan.fullAmount,
                            )}`}
                            {...radio('amountType', 'nextInstallment')}
                          />
                          <Radio
                            label="Repay a Custom Amount"
                            {...radio('amountType', 'custom')}
                          />
                        </RadioGroup>

                        {formState.values.amountType === 'custom' && (
                          <FormGroup>
                            <Input
                              amountField
                              required
                              placeholder="Amount"
                              classes="border-bottom"
                              {...text({
                                name: 'amount',
                                validate: value =>
                                  removeCommas(value) <= currentLoan.fullAmount,
                                validateOnBlur: true,
                              })}
                              errorMessage={
                                formState.touched.amount &&
                                !formState.validity.amount
                                  ? `Amount must not be more than ${currentLoan.fullAmount}`
                                  : ''
                              }
                            />
                          </FormGroup>
                        )}
                        <p className="lead-text bold-text">Choose Card</p>
                        <section className="debit-card-list">
                          {cards.map((card, index) => (
                            <DebitCard
                              cardDetails={card}
                              accountId={accountId}
                              key={index}
                              setPaymentType={setPaymentType}
                            />
                          ))}

                          <Fragment>
                            <StyledNewCard
                              onClick={() => {
                                  setPaymentType("payWtDiffMethod")
                                  selectedRepaymentMethodRef.current.checked = true
                                }
                              }
                            >
                              <Button
                                classes="new-card"
                                type="button"
                                disabled={
                                  formState.touched.amount &&
                                  !formState.validity.amount
                                }
                              >
                                <div>
                                  <Radio
                                    name="defaultCard"
                                    classes="payWtDiffMethod"
                                    id="payWtDiffMethod"
                                    ref={selectedRepaymentMethodRef}
                                  />
                                  <NewLoanIcon />
                                </div>
                                <div>Pay with a different method</div>
                              </Button>
                            </StyledNewCard>
            
                          </Fragment>
                        </section>
                        {!loading && !reference && (
                          <section className='referenceError'>
                            There was an error generating payment reference.
                            <button
                              className='retry'
                              onClick={() => {
                                getReference()
                              }}
                            >
                              Please, retry.
                            </button>
                          </section>
                        )}
                        {paymentType === "payWtDiffMethod" ? ( 
                          <div className="ps-button">
                            <PaystackButton
                                text="Make Repayment"
                                class="PayStackButton button psButton"
                                // class={`center ${loading ? 'loading' : ''}`}
                                embed={false}
                                reference={reference}
                                email={email}
                                close={closePaystackPopup}
                                amount={parseInt(
                                  removeCommas(getRepaymentAmount) * 100,
                                )}
                                paystackkey={paystackPubKey}
                                callback={makePaystackReferenceRepayment}
                                metadata={paystackMetadata}
                                tag="a"
                                channels={channel}
                            />
                          </div> 
                          ):
                          <Button
                            classes={`center ${loading ? 'loading' : ''}`}
                            disabled={
                              (formState.touched.amount &&
                              !formState.validity.amount) || !reference
                            }
                            click_event={makeCardOrManualRepayment}
                          >
                            Make Repayment
                          </Button>}
                      </form>
                    </DataCard>
                  ) : (
                    <Notification
                      icon={<CheckIcon />}
                      title="Payment Successful"
                    >
                      {amountPaid ? (
                        <p>
                          You have successfully repaid{' '}
                          <span className="bold-text">
                            <NairaAmount amount={amountPaid} />
                          </span>{' '}
                          out of your loan.
                        </p>
                      ) : (
                        <p>You have successfully made a repayment</p>
                      )}

                      <Button
                        classes="block"
                        click_event={() => {
                          window.location.href = '/dashboard';
                        }}
                      >
                        Go to your Dashboard
                      </Button>
                    </Notification>
                  )}
                </Fragment>
              )}
            </Fragment>
          </section>
        ) : (
          <section className="container no-loan">
            <DataCard>
              <div className="smiley-holder">
                <SadSmiley />
              </div>
              <h3 className="center-text">
                You currently do not have an active loan.
              </h3>
              <Button
                click_event={() => {
                  window.location.href = '/dashboard';
                }}
              >
                Go to your Dashboard
              </Button>
            </DataCard>
          </section>
        )
      }
    </ViewerProvider>
  );
};

export default withRouter(MakeRepayment);
