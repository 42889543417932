import React, { useEffect, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import { getClientId, useUserViewer } from '../../../hooks';
import { authTypes } from '../../../lib/constants';
import { useFlashMessage, useFormInput } from '../../Form';
import { LOGIN_MUTATION } from './queries';

let authCreds = {};
const LoginProvider = ({ children, history }) => {
  const clientId = getClientId();
  const inputs = {
    email: useFormInput(),
    password: useFormInput(),
  };

  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage, flashSuccess] = useFlashMessage();
  const [userToken, setUserToken] = useState(null);
  const { viewerData, refetch } = useUserViewer();

  const handleLogin = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');
    inputs.email.setErrorMessage('');
    inputs.password.setErrorMessage('');

    if (inputs.password.value && inputs.email.value) {
      runMutation({
        variables: {
          input: {
            email: inputs.email.value,
            password: inputs.password.value,
            clientId,
          },
        },
      });
    } else {
      if (!inputs.email.value)
        inputs.email.setErrorMessage('Email Address is required');
      if (!inputs.password.value)
        inputs.password.setErrorMessage('Password is required');
    }
  };

  useEffect(() => {
    if (userToken) {
      authCreds.apiKey = userToken;
      localStorage.setItem('Auth', JSON.stringify(authCreds));
      refetch();
    }
  }, [userToken]);

  useEffect(() => {
    if (viewerData?.loaded?.viewer) {
      const { applications } = viewerData?.loaded?.viewer.account;

      flashSuccess('Logged In Successfully');
      authCreds.keyType = authTypes.FIXED;
      
      if(userToken && authCreds){
        localStorage.setItem('Auth', JSON.stringify(authCreds))
      }

      const applicationCount = applications?.nodes?.length || 0;

      if (applicationCount === 0 && !viewerData?.loaded?.viewer?.me?.bvnStatus?.bvn) {
        history.push('/v1/sign-up/bvn-verification', { login: true });
        return;
      }

      if (
        applicationCount === 0 &&
        viewerData?.loaded?.viewer?.me?.bvnStatus?.bvn &&
        !viewerData?.loaded?.viewer?.me?.kycInformation
      ) {
        history.push('/v1/sign-up/customer-info', { login: true });
        return;
      }

      if (applicationCount === 0) {
        history.push('/application/new', { login: true });
        return;
      }

      if (
        applicationCount === 1 &&
        applications.nodes[applicationCount - 1]?.status?.name === 'PENDING'
      ) {
        history.push('/application/confirm-details', { login: true });
        return;
      }

      history.push('/dashboard');
    }
  }, [viewerData]);

  return (
    <Mutation
      mutation={LOGIN_MUTATION}
      onCompleted={async ({ login: { token } }) => {
        setUserToken(token);
      }}
      onError={error => {
        if (error.graphQLErrors.length === 0)
          flashError("We're unable to log you in now. Please try again later.");

        error.graphQLErrors.forEach(error => {
          switch (error.message) {
            case 'Cannot find a user with this email under the provided client':
              flashError('Invalid Email/Password');
              break;
            case 'Invalid password':
              flashError('Invalid Email/Password');
              break;
            default:
              flashError(
                "We're unable to log you in now. Please try again later.",
              );
          }
        });
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => handleLogin(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
        })
      }
    </Mutation>
  );
};

export default withRouter(LoginProvider);
