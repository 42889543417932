import { useHistory } from 'react-router';
import { NEW_LOAN_ROUTES } from '../containers/LoanApplication/routes';
import { usePaymentInformation } from '../containers/providers/Payment/PaymentInformationProvider';
import { useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { APPLICATION_STEPS } from '../lib/applicationSteps';
import { useDispatch } from 'react-redux';
import {
  setFullContextState,
  updateSingleContextState,
} from '../redux/application/contextStateRedux';
import { GET_DECIDE_JOB_STATUS } from '../containers/LoanApplication/queries';
import { useQuery } from '@apollo/client';

const defaultUserMetadata = {
  phoneNumber: null,
  email: null,
  address: null,
  city: null,
  localGovernment: null,
  state: null,
  dateMovedIn: null,
  employmentStatus: null,
  workAddress: null,
  jobRole: null,
  grossIncome: null,
  netIncome: null,
  employerName: null,
  workStartDate: null,
  hasExternalLoan: null,
  refereeName: null,
  refereePhone: null,
  title: null,
  maritalStatus: null,
  refereeWorkPlace: null,
  refereeEmail: null,
  facebookProfileUrl: null,
  gender: null,
  imageUrl: null,
  selfie: null,
  workId: null,
  workEmail: null,
  governmentId: null,
};

const canGoBack = true;

const bankStatementDoneSteps = [
  APPLICATION_STEPS.uploadBankStatement.name,
  APPLICATION_STEPS.completeBankStatementRequest.name,
  APPLICATION_STEPS.completeExternalBankStatementRequest.name,
  APPLICATION_STEPS.getOkraTransactions.name,
];

const useLoanApplicationRoute = ({
  clientInfo,
  viewer,
  application: applicationState,
}) => {
  const { application } = applicationState || {};
  const {
    data: { cards, loading: paymentInfoLoading },
  } = usePaymentInformation();
  const history = useHistory();
  const {
    params: { applicationNumber },
  } = useRouteMatch();
  const dispatch = useDispatch();

  const {
    requiresSelfieUpload,
    requiredSocialMediaAccounts,
    requiresGovernmentId,
    requiresWorkIDUpload,
    requireWorkEmail,
    requiresReferee,
    requiredSupportingDocuments,
    noCardImplementation,
    requiresBankStatementUpload,
  } = clientInfo;

  const userMeta = defaultUserMetadata;
  const completedStepsHashMap = {};

  const isLoaded =
    clientInfo?.slug &&
    viewer?.me?.id &&
    application?.id &&
    !paymentInfoLoading;

  const { me } = viewer || {};
  const {
    userMetadata: rawUserMetadata = [],
    supportingDocument = [],
    socialMediaInformation = [],
    isWorkEmailConfirmed,
    isPhoneConfirmed,
    isEmailConfirmed,
  } = me || {};
  const {
    requiredSteps,
    completedSteps = [],
    bankAccount: appBankAccount,
    credit,
  } = application || {};

  const ticketNumber = credit?.ticketNo

  const isApplicationStepPending = step =>
    requiredSteps?.includes(step) && !completedSteps?.includes(step);

  completedSteps.forEach(step => (completedStepsHashMap[step] = 1));
  // get user metadata
  useEffect(() => {
    if (rawUserMetadata?.length) {
      rawUserMetadata.forEach(meta => (userMeta[meta.name] = meta.value));
    }
  }, [rawUserMetadata]);

  const shouldUploadPassport = () => {
    return !userMeta.selfie;
  };

  const shouldUploadGovID = () => {
    return !userMeta.governmentId;
  };

  const shouldConnectRequiredSocialMediaConnected = () => {
    const connectedAccounts = {};

    socialMediaInformation.forEach(
      smi => (connectedAccounts[smi.provider] = smi.profileUrl),
    );

    return !requiredSocialMediaAccounts.every(
      ({ name }) => !!connectedAccounts[name],
    );
  };

  // const shouldFillEmploymentDetails = () => {
  //   return false;
  // };

  const shouldUploadWorkId = () => {
    return !userMeta.workId;
  };

  const shouldVerifyWorkEmail = () => {
    return !isWorkEmailConfirmed && requireWorkEmail;
  };

  const shouldProvideReferee = () => {
    const { refereeName, refereePhone, refereeWorkPlace } = userMeta;

    return !refereeName || !refereePhone || !refereeWorkPlace;
  };

  const shouldUploadSupportingDocuments = () => {
    // TODO: improve this check to find individual required documents
    return requiredSupportingDocuments.length > supportingDocument.length;
  };

  const shouldAddCard = () => {
    // TODO: Check for expired cards
    return !paymentInfoLoading && cards.length === 0;
  };

  const shouldSelectBankAccount = () => {
    if (!appBankAccount?.id) return true;

    return isApplicationStepPending(APPLICATION_STEPS.setBankAccount.name);
  };

  const { data } = useQuery(GET_DECIDE_JOB_STATUS, {
    variables: { input: { applicationId: application?.application?.id } },
    skip: !application?.application?.id
  });

  const shouldUploadBankStatement = () => {
    const pdfJobIsDone = ['DONE', 'SKIPPED'].includes(data?.getDecidePdfStatus?.status);
    if (bankStatementDoneSteps.find(step => !!completedStepsHashMap[step]) && !pdfJobIsDone)
      return false;

    return (
      isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name) ||
      requiresBankStatementUpload 
    );
  };

  const shouldShowExternalMbs = () => {
    if (
      bankStatementDoneSteps.find(step => !!completedStepsHashMap[step]) ||
      isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name)
    )
      return false;

    const isPending = isApplicationStepPending(
      APPLICATION_STEPS.completeExternalBankStatementRequest.name,
    );

    return !!isPending;
  };

  const shouldShowMbsOtp = () => {
    if (
      bankStatementDoneSteps.find(step => !!completedStepsHashMap[step]) ||
      isApplicationStepPending(APPLICATION_STEPS.uploadBankStatement.name)
    ) {
      return false;
    }
    
    return (
      !ticketNumber && requiredSteps?.includes("INITIATE_BANK_STATEMENT_REQUEST")
    );
  };

  const shouldAddAddress = () => {
    const { address, localGovernment, state } = userMeta;

    return !(address && localGovernment && state);
  };

  const shouldVerifyEmail = () => {
    return !isEmailConfirmed;
  };

  const shouldAddEmploymentDetails = () => {
    const { employmentStatus } = userMeta;
    return !employmentStatus;
  };

  const shouldVerifyPhone = () => {
    return (
      (!isPhoneConfirmed || isPhoneConfirmed == null)
    );
  };

  const fetchNextStep = steps => {
    let nextPage;
    switch (true) {
      case steps.loader:
        nextPage = NEW_LOAN_ROUTES.loader;
        break;
      case steps.verifyEmail:
        nextPage = NEW_LOAN_ROUTES.verifyEmail;
        break;
      case steps.verifyPhone:
        nextPage = NEW_LOAN_ROUTES.verifyPhone;
        break;
      case steps.homeAddress:
        nextPage = NEW_LOAN_ROUTES.homeAddress;
        break;
      case steps.employmentDetails:
        nextPage = NEW_LOAN_ROUTES.employmentDetails;
        break;
      case steps.selfie:
        nextPage = NEW_LOAN_ROUTES.passportUpload;
        break;
      case steps.socialMedia:
        nextPage = NEW_LOAN_ROUTES.socialMedia;
        break;
      case steps.utilityBill:
        nextPage = NEW_LOAN_ROUTES.utilityBillUpload;
        break;
      case steps.employmentLetter:
        nextPage = NEW_LOAN_ROUTES.employmentLetterUpload;
        break;
      case steps.workId:
        nextPage = NEW_LOAN_ROUTES.workIdUpload;
        break;
      case steps.govtId:
        nextPage = NEW_LOAN_ROUTES.idUpload;
        break;
      case steps.verifyWorkEmail:
        nextPage = NEW_LOAN_ROUTES.verifyWorkEmail;
        break;
      case steps.requireReferee:
        nextPage = NEW_LOAN_ROUTES.referee;
        break;
      case steps.requireSupportingDoc:
        nextPage = NEW_LOAN_ROUTES.supportingDocuments;
        break;
      case steps.addCard:
        nextPage = NEW_LOAN_ROUTES.addCard;
        break;
      case steps.bankAccount:
        nextPage = NEW_LOAN_ROUTES.bankAccount;
        break;
      case shouldShowExternalMbs():
        nextPage = NEW_LOAN_ROUTES.mbsInstruction;
        break;
      case shouldShowMbsOtp():
        nextPage = NEW_LOAN_ROUTES.mbsOtp;
        break;
      case steps.bankStatementUpload && shouldUploadBankStatement():
        nextPage = NEW_LOAN_ROUTES.uploadBankStatement;
        break;
      default:
        nextPage = NEW_LOAN_ROUTES.confirmLoan;
        break;
    }

    return nextPage;
  };

  const getNextPage = () => {
    return NEW_LOAN_ROUTES.loader;
  };

  const goToPage = (page, state) => {
    history.push(`/application/${applicationNumber}/${page}`, state);
  };

  const goToNextPage = () => {
    // const nextPage = getNextPage();
    // console.log(`/application/${applicationNumber}/${nextPage}`);
  };

  const goToPreviousPage = () => {
    if (canGoBack) {
      history.goBack();
    }
  };

  const goToLoanDetailsPage = () => {
    history.push(`/loans/details?applicationNumber=${applicationNumber}`);
  };

  const initRequiredStepsState = () => {
    const initStep = {
      loader: false,
      verifyEmail: shouldVerifyEmail(),
      verifyPhone: shouldVerifyPhone(),
      selfie: requiresSelfieUpload && shouldUploadPassport(),
      govtId: requiresGovernmentId && shouldUploadGovID(),
      socialMedia:
        requiredSocialMediaAccounts?.length &&
        shouldConnectRequiredSocialMediaConnected(),
      workId: requiresWorkIDUpload && shouldUploadWorkId(),
      verifyWorkEmail: requireWorkEmail && shouldVerifyWorkEmail(),
      requireReferee: requiresReferee && shouldProvideReferee(),
      requireSupportingDoc:
        requiredSupportingDocuments?.length &&
        shouldUploadSupportingDocuments(),
      addCard: !noCardImplementation && shouldAddCard(),
      bankAccount: shouldSelectBankAccount(),
      bankStatementUpload:
        shouldSelectBankAccount() || shouldUploadBankStatement(),
      mbs:
        shouldSelectBankAccount() ||
        shouldShowExternalMbs() ||
        shouldShowMbsOtp(),
      homeAddress: shouldAddAddress(),
      employmentDetails: shouldAddEmploymentDetails(),
    };
    dispatch(setFullContextState({ applicationNumber, steps: initStep }));
  };

  const setCompletedStep = currentStep => {
    dispatch(
      updateSingleContextState({ applicationNumber, step: currentStep }),
    );
  };

  return {
    isLoaded,
    goToNextPage,
    goToPage,
    goToPreviousPage,
    getNextPage,
    goToLoanDetailsPage,
    setCompletedStep,
    initRequiredStepsState,
    fetchNextStep,
  };
};

export default useLoanApplicationRoute;
